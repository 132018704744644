import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { TableAttachmentsComponent } from '@recall2/campaigns-core';
import { SVGIconModule } from '@recall2/icons';
import { AttachmentWrapperComponent, FileListComponent, provideRecall2Attachment } from '@recall2/ui/attachment';
import { Recall2AutoSuggestComponent } from '@recall2/ui/auto-suggest';
import { BrandManufacturerSelectorComponent } from '@recall2/ui/brand-manufacturer';
import {
  Recall2ButtonAnimationTogglerDirective,
  Recall2ButtonDownloadComponent,
  Recall2ButtonOutlineAddComponent,
  Recall2ButtonPrimaryComponent,
  Recall2ButtonSecondaryComponent,
  Recall2ButtonTertiaryComponent,
  Recall2SVGIconButtonDirective,
} from '@recall2/ui/buttons';
import { provideRecall2CommentsHistory, Recall2CommentsAndHistoryComponent } from '@recall2/ui/comments-history';
import { ReCall2CoreModule } from '@recall2/ui/core.module';
import { Recall2DatepickerComponent } from '@recall2/ui/datepicker';
import { Recall2DeadlineComponent } from '@recall2/ui/deadline';
import { Recall2FloatingBarComponent } from '@recall2/ui/floating-bar';
import { Recall2FootnoteRequiredComponent } from '@recall2/ui/footnote';
import { Recall2InputTextComponent } from '@recall2/ui/form/components/recall2-input-text';
import { Recall2SelectComponent } from '@recall2/ui/form/components/recall2-select';
import { Recall2TagsComponent } from '@recall2/ui/form/components/recall2-tags';
import { Recall2TextareaComponent } from '@recall2/ui/form/components/recall2-textarea';
import { Recall2ThreeStepCheckboxComponent } from '@recall2/ui/form/components/recall2-three-step-checkbox';
import { GhostSkeletonComponent } from '@recall2/ui/ghost-skeleton';
import {
  Recall2IconAttachmentComponent,
  Recall2IconCommentComponent,
  Recall2IconDeleteComponent,
  Recall2IconEditComponent,
  Recall2IconForwardComponent,
  Recall2IconLinkComponent,
  Recall2IconObjektMeldungComponent,
  Recall2IconUserComponent,
  Recall2IconWarningComponent,
} from '@recall2/ui/icons';
import { Recall2BrandLogoComponent } from '@recall2/ui/img-logo';
import { Recall2AccordionSimpleH3Component } from '@recall2/ui/layout/accordion';
import { Recall2BadgeComponent } from '@recall2/ui/layout/badge';
import { ReCall2SliderComponent } from '@recall2/ui/layout/slider';
import { Recall2StatusComponent } from '@recall2/ui/layout/status';
import { Recall2LoadingSpinnerComponent } from '@recall2/ui/loading-spinner';
import { Recall2ManufacturerTableComponent } from '@recall2/ui/manufacturer-table';
import { Recall2NavigationStepperComponent } from '@recall2/ui/navigation-stepper';
import { ReCall2FormCancelModalComponent } from '@recall2/ui/overlay';
import { OverlayLinkedObjectsComponent } from '@recall2/ui/overlay-linked-objects';
import { PaginationComponent } from '@recall2/ui/pagination';
import { CustomTimePipe, FileSizePipe, FullNamePipe, MarkEmptyPipe } from '@recall2/ui/pipes';
import { TableComponent } from '@recall2/ui/table';
import { Recall2UserLibraryModule } from '@recall2/user';

import { environment } from '../../environments/environment';
import { UIModule } from '../ui/ui.module';
import { NotificationEditorCell } from './components/cells/notification.editor.cell';
import { NotificationReporterCell } from './components/cells/notification.reporter.cell';
import { FormBrandSelectorComponent } from './components/form-brand-selector/form-brand-selector.component';
import { NotificationApproveComponent } from './components/notification-flow/notification-approve/notification-approve.component';
import { NotificationCommentComponent } from './components/notification-flow/notification-comment/notification-comment.component';
import { NotificationConfirmComponent } from './components/notification-flow/notification-confirm/notification-confirm.component';
import { NotificationDiscardComponent } from './components/notification-flow/notification-discard/notification-discard.component';
import { NotificationForwardComponent } from './components/notification-flow/notification-forward/notification-forward.component';
import { NotificationManufacturerComponent } from './components/notification-flow/notification-manufacturer/notification-manufacturer.component';
import {
  NotificationReworkAPSComponent,
  NotificationReworkComponent,
  NotificationReworkReviewerComponent,
} from './components/notification-flow/notification-rework/notification-rework.component';
import { NotificationSendComponent } from './components/notification-flow/notification-send/notification-send.component';
import { NotificationTransferComponent } from './components/notification-flow/notification-transfer/notification-transfer.component';
import { NotificationWarningComponent } from './components/notification-flow/notification-warning/notification-warning.component';
import { EditableInputTableComponent } from './components/notification-form/editable-input-table/editable-input-table.component';
import { NotificationDescriptionComponent } from './components/notification-form/notification-description/notification-description.component';
import { NotificationMeasureComponent } from './components/notification-form/notification-measure/notification-measure.component';
import { NotificationParentComponent } from './components/notification-form/notification-parent/notification-parent.component';
import { NotificationPersonsComponent } from './components/notification-form/notification-persons/notification-persons.component';
import { NotificationSummaryComponent } from './components/notification-form/notification-summary/notification-summary.component';
import { NotificationVehicleComponent } from './components/notification-form/notification-vehicle/notification-vehicle.component';
import type { ObjectType } from './components/notification-form/slider-content/slider-content.component';
import { SliderContentComponent } from './components/notification-form/slider-content/slider-content.component';
import { NotificationDetailsComponent } from './components/notification-overview/notification-details/notification-details.component';
import { NotificationOverviewComponent } from './components/notification-overview/notification-overview.component';
import { NotificationSummaryHeaderComponent } from './components/summary/sub-components/notification-summary-header/notification-summary-header.component';
import { NotificationSummaryMeasurementsComponent } from './components/summary/sub-components/notification-summary-measurements/notification-summary-measurements.component';
import { NotificationSummaryVehiclesComponent } from './components/summary/sub-components/notification-summary-vehicles/notification-summary-vehicles.component';
import { SummaryComponent } from './components/summary/summary.component';
import { AuthGuard } from './guards/auth-guard.service';
import { NotificationRoutingModule } from './notification-routing.module';
import { NotificationEffects } from './store/notification.effects';
import { notificationReducer } from './store/notification.reducer';

const CellComponents = [NotificationReporterCell, NotificationEditorCell];

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function historyUrlBuilder(_objectType: ObjectType, objectId: number): string {
  return `${environment.apiUrlNotification}${environment.NOTIFICATION_APP_OVERVIEW_URI}/${objectId}/events`;
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function commentsUrlBuilder(_objectType: ObjectType, objectId: number): string {
  return `${environment.apiUrlNotification}${environment.NOTIFICATION_APP_OVERVIEW_URI}/${objectId}/comments`;
}

@NgModule({
  declarations: [
    NotificationDescriptionComponent,
    NotificationMeasureComponent,
    NotificationParentComponent,
    NotificationVehicleComponent,
    NotificationPersonsComponent,
    NotificationOverviewComponent,
    CellComponents,
    NotificationSendComponent,
    NotificationDiscardComponent,
    SliderContentComponent,
    NotificationForwardComponent,
    NotificationConfirmComponent,
    NotificationReworkComponent,
    NotificationManufacturerComponent,
    NotificationReworkReviewerComponent,
    NotificationReworkAPSComponent,
    NotificationApproveComponent,
    NotificationTransferComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NotificationRoutingModule,
    NotificationSummaryComponent,
    NotificationSummaryHeaderComponent,
    NotificationSummaryMeasurementsComponent,
    NotificationSummaryVehiclesComponent,
    NotificationDetailsComponent,
    NotificationWarningComponent,
    EditableInputTableComponent,
    FormBrandSelectorComponent,
    NotificationCommentComponent,
    SummaryComponent,
    StoreModule.forFeature('notification', notificationReducer),
    EffectsModule.forFeature([NotificationEffects]),
    MatButtonModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatTooltipModule,
    MatDialogModule,
    MatTableModule,
    Recall2FootnoteRequiredComponent,
    Recall2DeadlineComponent,
    Recall2ManufacturerTableComponent,
    MatSortModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatNativeDateModule,
    UIModule,
    PaginationComponent,
    ReCall2FormCancelModalComponent,
    Recall2LoadingSpinnerComponent,
    Recall2AutoSuggestComponent,
    Recall2UserLibraryModule,
    Recall2FloatingBarComponent,
    Recall2NavigationStepperComponent,
    Recall2DatepickerComponent,
    ReCall2CoreModule,
    BrandManufacturerSelectorComponent,
    Recall2CommentsAndHistoryComponent,
    Recall2ButtonPrimaryComponent,
    Recall2ButtonAnimationTogglerDirective,
    Recall2ButtonSecondaryComponent,
    Recall2ButtonTertiaryComponent,
    Recall2ButtonOutlineAddComponent,
    TableComponent,
    SVGIconModule,
    Recall2SVGIconButtonDirective,
    GhostSkeletonComponent,
    Recall2ButtonDownloadComponent,
    TableAttachmentsComponent,
    OverlayLinkedObjectsComponent,
    Recall2InputTextComponent,
    Recall2TagsComponent,
    Recall2TextareaComponent,
    Recall2ThreeStepCheckboxComponent,
    Recall2SelectComponent,
    MarkEmptyPipe,
    FullNamePipe,
    CustomTimePipe,
    FileSizePipe,
    Recall2AccordionSimpleH3Component,
    Recall2StatusComponent,
    Recall2BadgeComponent,
    ReCall2SliderComponent,
    Recall2IconUserComponent,
    Recall2IconWarningComponent,
    Recall2IconDeleteComponent,
    Recall2IconCommentComponent,
    Recall2IconAttachmentComponent,
    Recall2IconObjektMeldungComponent,
    Recall2IconForwardComponent,
    Recall2IconEditComponent,
    Recall2IconLinkComponent,
    Recall2BrandLogoComponent,
    FileListComponent,
    AttachmentWrapperComponent,
  ],
  providers: [
    AuthGuard,
    provideRecall2Attachment({ baseUrl: '/api-preliminary-attachment/attachments' }),
    provideRecall2CommentsHistory({
      statusComponentSpec: {
        NOTIFICATION: {
          DRAFT: { color: 'RED', iconType: 'FILLED', translationKey: 'notification.status.draft' },
          TO_REVISE_REVIEWER: {
            color: 'YELLOW',
            iconType: 'HOLLOW',
            translationKey: 'notification.status.rework.reviewer',
          },
          TO_REVISE_BRAND_OFFICE: {
            color: 'YELLOW',
            iconType: 'HOLLOW',
            translationKey: 'notification.status.rework.brandOffice',
          },
          REVIEW: { color: 'YELLOW', iconType: 'FILLED', translationKey: 'notification.status.review' },
          APPROVED: { color: 'GREEN', iconType: 'HOLLOW', translationKey: 'notification.status.approved' },
          ACCEPTED: { color: 'GREEN', iconType: 'FILLED', translationKey: 'notification.status.accepted' },
          LINKED: { color: 'GREEN', iconType: 'FILLED', translationKey: 'notification.status.linked' },
          DISCARDED: { color: 'GREY', iconType: 'HOLLOW', translationKey: 'notification.status.discarded' },
        },
      },
      historyUrlBuilder,
      commentsUrlBuilder,
    }),
  ],
  exports: [StoreModule, EffectsModule],
})
export class NotificationModule {}
