import type { AfterViewInit, OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Recall2InputTextV2Component } from '../../../../form/components/recall2-input-text-v2/recall2-input-text-v2.component';
import { InputTextProperty } from '../../../../form/model';
import type { IRecall2FilterInputProperty, IRecall2FilterParam } from '../../../models/filter.model';
import { ETextFilterComparator, IRecall2FilterTextParam } from '../../../models/filter.model';
import { Recall2FilterUpdateCloseComponent } from '../recall2-filter-update-close/recall2-filter-update-close.component';

@Component({
  selector: 'recall2-filter-text-v2',
  templateUrl: './recall2-filter-text-v2.component.html',
  styleUrls: ['./recall2-filter-text-v2.component.scss'],
  standalone: true,
  imports: [Recall2InputTextV2Component, Recall2FilterUpdateCloseComponent],
})
export class Recall2FilterTextV2Component implements OnInit, AfterViewInit {
  @Input() currentSelectedFilter: IRecall2FilterTextParam;
  @Output() selectedFilter = new EventEmitter<IRecall2FilterParam>();
  @Output() closeFilter = new EventEmitter<void>();

  @ViewChild('inputComponent') textInputComponent: Recall2InputTextV2Component;

  filterInput: IRecall2FilterInputProperty;

  isValidForm = false;

  ngOnInit(): void {
    this.filterInput = {
      inputTextFilterProperty: new InputTextProperty(
        'filterInputProperty',
        true,
        this.getTranslationKey(),
        { maxLength: 40 },
        false,
        new FormControl(''),
        false,
      ),
      isEditIconShown: false,
    };

    this.currentSelectedFilter.comparator = ETextFilterComparator.EQUALS;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.textInputComponent) {
        this.textInputComponent.setFocus();
      }
    }, 100);
  }

  updateValidity(event): void {
    this.isValidForm = event.target.value.trim().length > 0;
  }

  emitFilter(): void {
    this.currentSelectedFilter.value = [];

    this.currentSelectedFilter.value.push(this.filterInput.inputTextFilterProperty.control.value);

    const newResult = { ...this.currentSelectedFilter };
    this.selectedFilter.emit(newResult);
  }

  private getTranslationKey(): string {
    return this.currentSelectedFilter && this.currentSelectedFilter.translationKey
      ? this.currentSelectedFilter.translationKey
      : 'recall2-input-text-filter';
  }
}
