import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';
import { AttachmentType } from '@recall2/campaigns-core';
import { iconAttachMedium, iconEditMedium, iconLinkMedium, iconMeldungMedium, SVGIconsRegistry } from '@recall2/icons';
import type { DownloadButtonConfig } from '@recall2/ui/buttons';
import { DownloadType } from '@recall2/ui/buttons';
import { SortingOrder } from '@recall2/ui/core/api/enums';
import type { FieldSorting } from '@recall2/ui/core/api/models';
import { EObjectType } from '@recall2/ui/dynamic-content';
import { GhostSkeletonSchema } from '@recall2/ui/ghost-skeleton';
import { EStatus } from '@recall2/ui/layout/status/model/status.model';
import type { IRecall2FilterItem, IRecall2SortItem, Recall2FilterV2Config } from '@recall2/ui/overlay';
import type { PageDTO } from '@recall2/ui/pagination';
import { TabTitleService } from '@recall2/ui/tab-title';
import type { TableColumnConfig, TableService } from '@recall2/ui/table';
import { getContainingEntry, getMappedFilters, getMappedSort, TableServiceFactory } from '@recall2/ui/table';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { UserService } from '../../../rest-api/user/user.service';
import type { UserData } from '../../../user/store/user.state';
import type { INotificationForList, IUpdateOverview } from '../../models/notification/notification';
import { NotificationService } from './../../../rest-api/notification/notification.service';
import { NotificationRoutingService } from './../../routing/notification-routing.service';
import { notificationOverviewTableFiltersConfiguration } from './notification-overview-filters-configuration';
import { NotificationOverviewTableColumns } from './notification-overview-table-columns.enum';
import { notificationOverviewTableConfig } from './notification-overview-table-config';

@Component({
  selector: 'notification-overview',
  templateUrl: './notification-overview.component.html',
  styleUrls: ['./notification-overview.component.scss'],
})
export class NotificationOverviewComponent implements OnDestroy, OnInit {
  @ViewChild('edit', { static: true }) editTemplate: TemplateRef<unknown>;
  @ViewChild('icon', { static: true }) iconTemplate: TemplateRef<unknown>;
  @ViewChild('reporter', { static: true }) reporterTemplate: TemplateRef<unknown>;
  @ViewChild('editor', { static: true }) editorTemplate: TemplateRef<unknown>;
  @ViewChild('date', { static: true }) dateTemplate: TemplateRef<unknown>;
  @ViewChild('dueTo', { static: true }) dueToTemplate: TemplateRef<unknown>;
  @ViewChild('status', { static: true }) statusTemplate: TemplateRef<unknown>;
  @ViewChild('statusFilter', { static: true }) statusFilterTemplate: TemplateRef<unknown>;
  @ViewChild('attachments', { static: true }) attachmentsTemplate: TemplateRef<unknown>;
  @ViewChild('linkedObjects', { static: true }) linkedObjectsTemplate: TemplateRef<unknown>;
  @ViewChild('headerLink', { static: true }) headerLinkTemplate: TemplateRef<unknown>;
  @ViewChild('headerAttachment', { static: true }) headerAttachmentTemplate: TemplateRef<unknown>;

  notifications: INotificationForList[] = [];
  currentUser: UserData;
  tableConfig: TableColumnConfig[];
  tableService: TableService;
  isLoading = true;
  filters: IRecall2FilterItem[] = [];
  sort: IRecall2SortItem[] = [];
  filterConfiguration: Recall2FilterV2Config[] = [];

  readonly tableId = 'notificationOverview';
  readonly GhostSkeletonSchema = GhostSkeletonSchema;
  readonly EStatus = EStatus;
  readonly AttachmentType = AttachmentType;
  readonly EObjectType = EObjectType;
  readonly downloadButtonConfig: DownloadButtonConfig = {
    url: `${environment.apiUrlNotification}/report/notifications/csv`,
    downloadType: DownloadType.POST,
    title: 'common.export',
  };
  readonly attachmentsApi = environment.apiUrlAttachment;
  readonly linkedObjectsApi = environment.apiUrlNotification;

  private destroyed$ = new Subject<void>();
  private readonly defaultSort: FieldSorting = {
    field: NotificationOverviewTableColumns.ReceivedDate,
    orderBy: SortingOrder.Desc,
  };

  constructor(
    private notificationRouting: NotificationRoutingService,
    private notificationService: NotificationService,
    private tabTitleService: TabTitleService,
    private tableServiceFactory: TableServiceFactory,
    private cdr: ChangeDetectorRef,
    private iconsRegistry: SVGIconsRegistry,
    private userService: UserService,
  ) {
    this.iconsRegistry.registerIcons([iconEditMedium, iconMeldungMedium, iconLinkMedium, iconAttachMedium]);
    this.initTableService();
  }

  ngOnInit(): void {
    this.setBrowserTabTitle();
    this.setTableColumnsConfig();
    this.loadInitialData();
    this.watchTableChanges();
  }

  createNotification = (): Promise<boolean> => this.notificationRouting.navigateToCreateForm();

  openNotificationById(id: number): void {
    this.notificationRouting.navigateToEditForm(id);
  }

  onRefreshTable(updateOverview: IUpdateOverview): void {
    this.loadNotifications(updateOverview.notification.id);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private setBrowserTabTitle(): void {
    this.tabTitleService.setTitleByKey('preliminary.overview.tab-title');
  }

  private loadInitialData(): void {
    this.userService
      .getCurrentUser$()
      .pipe(first())
      .subscribe(currentUser => (this.currentUser = currentUser));
  }

  private loadNotifications(id?: number): void {
    this.updateLoader(true);

    const { page, pageSize, activeSort, activeFilters } = this.tableService;

    this.filters = getMappedFilters(activeFilters);
    this.sort = getMappedSort(activeSort);

    const containingEntry = id ?? getContainingEntry();
    this.notificationService
      .getNotificationsForList(page, pageSize, containingEntry, this.filters, this.sort)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(response => {
        this.setTableData(response);
        this.updateLoader(false);
        if (id) {
          this.notificationRouting.navigateToOverviewAndHighlight(id).then();
        }
      });
  }

  private setTableData(data: PageDTO<INotificationForList>): void {
    this.notifications = data.content;
    this.tableService.updateTotalElements(data.totalElements);
    this.tableService.updateTotalPages(data.totalPages);
  }

  private updateLoader(newValue: boolean): void {
    this.isLoading = newValue;
    this.cdr.markForCheck();
  }

  private initTableService(): void {
    this.tableService = this.tableServiceFactory.add(this.tableId, { sort: this.defaultSort });
  }

  private setTableColumnsConfig(): void {
    const config = {
      editTemplate: this.editTemplate,
      iconTemplate: this.iconTemplate,
      reporterTemplate: this.reporterTemplate,
      editorTemplate: this.editorTemplate,
      dateTemplate: this.dateTemplate,
      dueToTemplate: this.dueToTemplate,
      statusTemplate: this.statusTemplate,
      attachmentsTemplate: this.attachmentsTemplate,
      linkedObjectsTemplate: this.linkedObjectsTemplate,
      headerLinkTemplate: this.headerLinkTemplate,
      headerAttachmentTemplate: this.headerAttachmentTemplate,
    };

    this.tableConfig = notificationOverviewTableConfig(config);
    this.filterConfiguration = notificationOverviewTableFiltersConfiguration(this.statusFilterTemplate);
  }

  private watchTableChanges(): void {
    this.tableService.tableHasChanges$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.loadNotifications();
    });

    this.tableService.isTableRefreshing$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(isRefreshing => this.updateLoader(isRefreshing));
  }
}
