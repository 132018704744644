import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AdminPanelRoutingModule, GET_ADMIN_PANEL_USER_ROLES } from '@recall2/ui/admin-panel';
import {
  ErrorHandlingEffects,
  HttpErrorResponseInterceptor,
  IdNotFoundInterceptor,
  Recall2ErrorHandlingModalComponent,
} from '@recall2/ui/errorhandling';
import { ExternalRoutingModule } from '@recall2/ui/external-url';
import { FeaturePhase, provideRecall2FeatureFlag } from '@recall2/ui/feature-flag';
import { FooterComponent } from '@recall2/ui/footer';
import { HeaderComponent } from '@recall2/ui/header';
import { HttpTranslateLoader } from '@recall2/ui/i18n';
import type { ERole } from '@recall2/ui/navbar';
import { NavbarComponent } from '@recall2/ui/navbar';
import { provideNewRelic } from '@recall2/ui/new-relic';
import { ToastContainerComponent } from '@recall2/ui/toast';
import { Recall2UserLibraryModule } from '@recall2/user';
import { type Observable } from 'rxjs';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { provideAuth } from './core/app-initializer';
import { HomePageModule } from './home-page/home-page.module';
import { LandingPageModule } from './home-page/landing-page/landing-page.module';
import { WelcomePageModule } from './home-page/welcome-page/welcome-page.module';
import { NotificationModule } from './notification/notification.module';
import { RestAPIModule } from './rest-api/rest-api.module';
import { UserServiceImpl } from './rest-api/user-create/user-service-impl.service';
import { RoutingModule } from './routing/routing.module';
import { appReducer } from './store/app/app.reducer';
import { initialAppState } from './store/app/app.state';
import { UIModule } from './ui/ui.module';
import { UserModule } from './user/user.module';
import { PermissionService } from './utility/permission/permission.service';

registerLocaleData(localeDe);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (translateLoader: HttpTranslateLoader): HttpTranslateLoader => translateLoader,
        deps: [HttpTranslateLoader],
      },
      isolate: false,
    }),
    UIModule,
    RoutingModule,
    UserModule,
    StoreModule.forRoot(appReducer, {
      initialState: initialAppState,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
      },
    }),
    EffectsModule.forRoot(ErrorHandlingEffects),
    StoreDevtoolsModule.instrument({
      name: 'vw-recall2-preliminary',
      connectInZone: true,
    }),
    ExternalRoutingModule,
    AdminPanelRoutingModule,
    BrowserAnimationsModule,
    Recall2ErrorHandlingModalComponent,
    RestAPIModule.forRoot(),
    HomePageModule,
    LandingPageModule,
    NotificationModule,
    NavbarComponent,
    HeaderComponent,
    FooterComponent,
    Recall2UserLibraryModule.forFeature(UserServiceImpl),
    WelcomePageModule,
    ToastContainerComponent,
  ],
  providers: [
    provideAuth(),
    provideNewRelic({ enabled: environment.newRelic.enabled, setting: environment.newRelic.setting }),
    provideRecall2FeatureFlag({
      enabled: true,
      featuresPhases: [FeaturePhase.Shared, FeaturePhase.Preliminary],
      isDevelopmentEnv: environment.featureFlagDevelopment,
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IdNotFoundInterceptor,
      multi: true,
    },
    DatePipe,
    {
      provide: GET_ADMIN_PANEL_USER_ROLES,
      useFactory: (permissionService: PermissionService): Observable<ERole[]> => permissionService.getUserRoles(),
      deps: [PermissionService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
