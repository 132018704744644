import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppTranslationService } from '@recall2/ui/i18n';
import { TabTitleService } from '@recall2/ui/tab-title';
import type { Observable } from 'rxjs';

import { NotificationService } from '../../../../rest-api/notification/notification.service';
import type { IAppState } from '../../../../store/app/app.state';
import type { NotificationStatus } from '../../../models/notification/notification-status.enum';
import { NotificationRoutingService } from '../../../routing/notification-routing.service';
import { NotificationBaseComponent } from '../notification-base/notification-base.component';
import { NotificationConfirmComponent } from '../notification-confirm/notification-confirm.component';

@Component({
  selector: 'recall2-notification-rework-reviewer',
  template: '<recall2-notification-rework [fromReviewer]="true"></recall2-notification-rework>',
})
export class NotificationReworkReviewerComponent {}

@Component({
  selector: 'recall2-notification-rework-aps',
  template: '<recall2-notification-rework [fromAPS]="true"></recall2-notification-rework>',
})
export class NotificationReworkAPSComponent {}

@Component({
  selector: 'recall2-notification-rework',
  templateUrl: './notification-rework.component.html',
})
export class NotificationReworkComponent extends NotificationBaseComponent implements OnInit, OnDestroy {
  @Input() fromReviewer: boolean;
  @Input() fromAPS: boolean;

  @ViewChild('confirmComponent') confirmComponent: NotificationConfirmComponent;

  constructor(
    modal: MatDialog,
    store: Store<IAppState>,
    notificationService: NotificationService,
    routingService: NotificationRoutingService,
    activatedRoute: ActivatedRoute,
    tabTitleService: TabTitleService,
    translationService: AppTranslationService,
  ) {
    super(modal, store, notificationService, routingService, activatedRoute, tabTitleService, translationService);
  }

  cancelRework(): void {
    this.confirmResetAction('notifications.form.reworkModal.title', () => {
      this.routingService.navigateToBaseRouteNotification(this.notificationId, true);
    });
  }

  isSubmitOk(): boolean {
    return !!this.comment;
  }

  async sendNotificationToRework() {
    return super.performAction(
      () => this.sendForRevise(),
      () => this.routingService.navigateToBaseRouteNotification(this.notificationId, true),
    );
  }

  sendForRevise(): Observable<NotificationStatus> {
    if (this.fromReviewer) {
      return this.notificationService.sendForReviseReviewer(this.notificationId, this.currentLocale, this.comment);
    }
    if (this.fromAPS) {
      return this.notificationService.sendForReviseAPS(this.notificationId, this.currentLocale, this.comment);
    }
    throw new Error('Parameter is missing!');
  }
}
