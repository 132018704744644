import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import type { OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import type { MatCheckboxChange } from '@angular/material/checkbox';
import { MatCheckboxModule } from '@angular/material/checkbox';
import type { MatSelectConfig } from '@angular/material/select';
import { MAT_SELECT_CONFIG, MatSelect, MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  iconCheckBoldMedium,
  iconClearMedium,
  iconWarningMedium,
  SVGIconModule,
  SVGIconsRegistry,
} from '@recall2/icons';
import type { Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { Recall2IconArrowDownComponent } from '../../../icons/recall2-icon-arrow-down/recall2-icon-arrow-down.component';
import { Recall2IconArrowUpComponent } from '../../../icons/recall2-icon-arrow-up/recall2-icon-arrow-up.component';
import { Recall2IconHelpComponent } from '../../../icons/recall2-icon-help/recall2-icon-help.component';
import { Recall2IconInvalidComponent } from '../../../icons/recall2-icon-invalid/recall2-icon-invalid.component';
import { Recall2IconRequiredComponent } from '../../../icons/recall2-icon-required/recall2-icon-required.component';
import { IncludesPipe } from '../../../pipes/includes/includes.pipe';
import { MarkEmptyPipe } from '../../../pipes/mark-empty/mark-empty.pipe';
import { Recall2AllowedCharactersDirective } from '../../directives/recall2-allowed-characters';
import { Recall2UpperCaseDirective } from '../../directives/recall2-uppercase';
import type { IRecall2SelectChange, SelectOption } from '../../model';
import { SelectProperty } from '../../model';
import { ReCall2SelectOptionDirective } from './recall2-select.directive';

export const DEFAULT_TRANSLATION_KEY_SELECT_ALL_KEY = 'shared.multi-select.all';

@Component({
  selector: 'recall2-select',
  standalone: true,
  templateUrl: './recall2-select.component.html',
  styleUrls: ['./recall2-select.component.scss'],
  viewProviders: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {
        overlayPanelClass: 'select-overlay--alignment',
      } as MatSelectConfig,
    },
  ],
  imports: [
    NgIf,
    NgClass,
    NgFor,
    NgTemplateOutlet,
    ReactiveFormsModule,
    MatTooltipModule,
    MatSelectModule,
    MatCheckboxModule,
    TranslateModule,
    SVGIconModule,
    IncludesPipe,
    MarkEmptyPipe,
    Recall2UpperCaseDirective,
    Recall2AllowedCharactersDirective,
    Recall2IconHelpComponent,
    Recall2IconRequiredComponent,
    Recall2IconInvalidComponent,
    Recall2IconArrowUpComponent,
    Recall2IconArrowDownComponent,
    ReCall2SelectOptionDirective,
  ],
})
export class Recall2SelectComponent implements OnChanges, OnDestroy {
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;

  @Input() set property(selectProperty: SelectProperty) {
    this._property = { ...selectProperty };

    if (selectProperty.readOnly) {
      this._property.control.disable({ onlySelf: true, emitEvent: false });
    } else {
      this._property.control.enable({ onlySelf: true, emitEvent: false });
    }

    this.originalOptionList = this._property?.optionsList?.length > 0 ? [...this._property.optionsList] : [];
    this.filteredOptionList = [...this.originalOptionList];
    this.setReadOnlyValue();
    this.watchControlValueChanges();
  }

  get property(): SelectProperty {
    return this._property;
  }

  @Input() isFormSubmitted: boolean;
  @Input() includedValues: string[] = [];
  @Input() dataToSkip: string[];
  @Input() customOverlayOptionClass = '';
  @Input() isSearchable = false;
  @Input() isStaticFilter = true;
  @Input() isClearable = false;
  @Input() isMultiple = false;
  @Input() translationKeySelectAll = DEFAULT_TRANSLATION_KEY_SELECT_ALL_KEY;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onChange = new EventEmitter<string>();
  @Output() searchText = new EventEmitter<string>();
  @Output() deleted = new EventEmitter<void>();

  @ViewChild(MatSelect) matSelect: MatSelect;
  @ContentChild(ReCall2SelectOptionDirective, { read: TemplateRef })
  itemTemplate: TemplateRef<ReCall2SelectOptionDirective>;

  isOpened: boolean;
  readOnlyValue = '';
  filteredOptionList: SelectOption[] = [];
  showClearIcon = false;

  private _property: SelectProperty;
  private originalOptionList: SelectOption[] = [];
  private destroy$ = new Subject<void>();
  private controlValueChangesSubscription: Subscription;

  constructor(
    private iconsRegistry: SVGIconsRegistry,
    private translateService: TranslateService,
  ) {
    this.iconsRegistry.registerIcons([iconCheckBoldMedium, iconWarningMedium, iconClearMedium]);
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges['dataToSkip']?.currentValue) {
      this.updateOptionList();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get showOptionSelectAll(): boolean {
    if (this.isMultiple && this.property.optionsList.length > 0 && this.isSearchable) {
      return this.searchInput?.nativeElement.value.length === 0;
    }

    return this.isMultiple && this.property.optionsList.length > 0;
  }

  get showErrors(): boolean {
    if (typeof this.isFormSubmitted === 'undefined') {
      return this.property.control.invalid && (this.property.control.dirty || this.property.control.touched);
    }

    return this.isFormSubmitted && !!this.property.control.errors;
  }

  get isAllSelectionIndeterminate(): boolean {
    return !!(
      this.property.optionsList.length > 0 &&
      this.property.control.value?.length &&
      this.property.control.value?.length < this.property.optionsList.length
    );
  }

  get isAllSelectionChecked(): boolean {
    return !!(
      this.property.optionsList.length > 0 &&
      this.property.control.value?.length &&
      this.property.control.value.length === this.property.optionsList.length
    );
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === ' ' || event.key === 'Enter') {
      event.stopPropagation();
    }

    if (event.key === 'Enter' && this.filteredOptionList.length === 1) {
      const value = this.filteredOptionList[0].value;
      this.property.control.setValue(value);
      this.matSelect.close();
      this.isOpened = false;
      this.onChange.emit(value);
    }
  }

  onSelectionChange(event: IRecall2SelectChange): void {
    this.onChange.emit(event.value);
  }

  onOpenedChange(isOpened: boolean): void {
    this.isOpened = isOpened;

    if (!this.isSearchable) {
      return;
    }

    this.filteredOptionList = [...this.property.optionsList];

    if (!this.searchInput) {
      return;
    }

    if (isOpened) {
      this.searchInput.nativeElement.focus();
    } else {
      this.searchInput.nativeElement.value = '';
    }
  }

  onSearchChange(event: KeyboardEvent): void {
    const searchText = (event.target as HTMLInputElement).value;
    if (this.isStaticFilter) {
      this.filteredOptionList = this.property.optionsList.filter(item =>
        item.key.toLowerCase().includes(searchText.toLowerCase()),
      );
      const panel = this.matSelect.panel.nativeElement as HTMLDivElement;
      panel.scrollTop = 0;
    } else {
      this.searchText.emit(searchText);
    }
  }

  onDelete(event: MouseEvent): void {
    event.stopPropagation();
    this.property.control.setValue('');
    this.filteredOptionList = [...this.property.optionsList];
    this.matSelect.open();
    this.deleted.emit();
  }

  onToggleSelectAll(change: MatCheckboxChange): void {
    const newValue = change.checked ? this.getValuesFromOptionsList() : [];
    this.property.control.setValue(newValue);
    this.onChange.emit(newValue as unknown as string);
  }

  trackByFn(_index: number, value: SelectOption): string {
    return value.value;
  }

  private getValuesFromOptionsList(): string[] {
    return this.property.optionsList?.map(option => option.value) ?? [];
  }

  private updateOptionList(): void {
    if (this.originalOptionList.length === 0) {
      return;
    }

    this.property.optionsList =
      this.dataToSkip.length > 0
        ? this.originalOptionList.filter(
            (selectOption: SelectOption) =>
              !this.dataToSkip.includes(selectOption.value) || this.property.control.value === selectOption.value,
          )
        : [...this.originalOptionList];
  }

  private watchControlValueChanges(): void {
    if (this.controlValueChangesSubscription) {
      this.controlValueChangesSubscription.unsubscribe();
    }

    this.controlValueChangesSubscription = this.property.control.valueChanges
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe(() => {
        this.setReadOnlyValue();
        this.setShowClearIcon();
      });
    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setReadOnlyValue();
    });
  }

  private setShowClearIcon(): void {
    if (!this.isClearable || this.property.readOnly) {
      this.showClearIcon = false;
      return;
    }

    if (
      !this.property.control.value ||
      (Array.isArray(this.property.control.value) && this.property.control.value.length === 0)
    ) {
      this.showClearIcon = false;
      return;
    }

    this.showClearIcon = true;
  }

  private setReadOnlyValue(): void {
    if (this.isMultiple || Array.isArray(this.property.control.value)) {
      this.makeReadOnlyValueMultiSelect();

      return;
    }

    this.readOnlyValue = this.getTranslateValue(this.property.control.value);
  }

  private makeReadOnlyValueMultiSelect(): void {
    if (this.isAllSelectionChecked) {
      this.readOnlyValue = this.translateService.instant(this.translationKeySelectAll);
      return;
    }

    if (!Array.isArray(this.property.control.value) || this.property.control.value.length === 0) {
      this.readOnlyValue = '';
      return;
    }

    this.readOnlyValue = this.property.control.value
      .map(value => {
        return this.getTranslateValue(value);
      })
      .join(', ');
  }

  private getTranslateValue(value: string): string {
    if (!value) {
      return '';
    }

    const optionKey = this.findOptionKey(value);
    return optionKey ? this.translateService.instant(optionKey) : '';
  }

  private findOptionKey(value: string): string {
    const option = this.property.optionsList.find(option => option.value === value);
    return option ? option.key : this.property.control.value;
  }
}
