import { mapToCanActivate, type Routes } from '@angular/router';
import { UserFlowGuard } from '@recall2/ui/user-flow';

import { LandingPageComponent } from '../home-page/landing-page/landing-page.component';
import { MyServePageComponent } from '../home-page/my-serve-page/my-serve-page.component';
import { WelcomePageComponent } from '../home-page/welcome-page/welcome-page/welcome-page.component';

/**
 * Development Route Table
 */
const devRoutes: Routes = [
  {
    path: 'landing',
    component: LandingPageComponent,
  },
  {
    path: 'welcome',
    component: WelcomePageComponent,
    canActivate: mapToCanActivate([UserFlowGuard]),
  },
  {
    path: 'myserve',
    component: MyServePageComponent,
    canActivate: mapToCanActivate([UserFlowGuard]),
    data: UserFlowGuard.setOptions({ allowInactiveUsers: true }),
  },
  { path: '', redirectTo: 'welcome', pathMatch: 'full' },
];

export const mainRoutes = devRoutes;
