import type { AfterViewInit, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import type { IAutoSuggestUser } from '@recall2/ui/auto-suggest';
import type { IColumnDefinition } from '@recall2/ui/dynamic-content';
import { ColumnDefinition } from '@recall2/ui/dynamic-content';
import {
  ELabelAlignment,
  EThreeStepCheckboxStates,
  InputTextProperty,
  ThreeStepCheckboxProperty,
} from '@recall2/ui/form/model';
import { ThreeStepCellComponent } from '@recall2/ui/manufacturer-table';
import { ERole } from '@recall2/ui/navbar';
import { Recall2TableFacadeBuilder } from '@recall2/ui/tables';
import { cloneDeep, compareObjects } from '@recall2/ui/utils';
import { of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Recall2AddNewUserComponent } from '../../add-new-user/component/recall2-add-new-user/recall2-add-new-user.component';
import { AutoSuggestCell } from '../../cells/involved-user-table/auto-suggest-cell/auto-suggest-cell.component';
import { CommitteeTranscriberCell } from '../../cells/involved-user-table/committee-transcriber-cell/committee-transcriber.cell';
import { DeleteIconCell } from '../../cells/involved-user-table/delete-icon-cell/delete-icon.cell';
import { EntitledToVoteCell } from '../../cells/involved-user-table/entitled-to-vote-cell/entitled-to-vote.cell';
import { InheritToAgendaItemCell } from '../../cells/involved-user-table/inherit-to-agenda-item-cell/inherit-to-agenda-item.cell';
import { SubjectAreaEditCell } from '../../cells/involved-user-table/subject-area-edit-cell/subject-area-edit-cell.component';
import type { InvolvedUser } from '../../model/involved-user.model';
import { EInvolvedUserTableType, IInvolvedUserAutoSuggestProperties } from '../../model/involved-user.model';
import type { GridColumnDefinition } from '../../model/involved-user-table.model';
import {
  EInvolvedUserTableHeader,
  getDefaultColumnDefinitions,
  getExtendedColumnDefinitions,
} from '../../model/involved-user-table.model';
import { EUserType } from '../../model/user.model';
import { UserService } from '../../user.service';

@Component({
  selector: 'recall2-involved-users-table',
  styleUrls: ['./recall2-involved-users-table.component.scss'],
  templateUrl: './recall2-involved-users-table.component.html',
})
export class Recall2InvolvedUsersTableComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {
  allPresentProperty = new ThreeStepCheckboxProperty(
    'allPresent',
    false,
    'general.all.present',
    false,
    new FormControl(),
    true,
  );
  subjectAreaInput = new InputTextProperty(
    'subjectAreaInput',
    true,
    'involvedUserTable.subjectArea',
    {},
    false,
    new FormControl('', [Validators.required]),
    false,
  );
  readonly EXPERT: string = ERole[ERole.EXPERT];
  // this is to update tempUserList only once when it is dependant on observable, after
  // the first init we should not update tempUserList again
  readonly alignmentTypes = ELabelAlignment;
  tableFacade: Recall2TableFacadeBuilder;
  isAddAllowed = true;
  // list that contains userList + emptyEntry for autoSuggest (when adding a new user)
  tempUserList: InvolvedUser[];

  private oldUser: InvolvedUser[];
  private destroyed$ = new Subject<void>();
  private columnDefinitions: ColumnDefinition[];
  private addingNewUser = false;
  private defaultSorting = [];

  @Input() userList: InvolvedUser[] | any[] = [];
  @Input() previousUrl: string[];
  @Input() attendanceColumn: ColumnDefinition = null;
  @Input() tableId: string;
  @Input() disableTranscriberCheckBox = false;
  @Input() disableVoteCheckBox = false;
  @Input() showTranscriberCheckbox = true;
  @Input() showVoteCheckBox = true;
  @Input() showAddButton = true;
  @Input() isSummaryTable = false;
  @Input() showTitle = true;
  @Input() tableType: EInvolvedUserTableType = EInvolvedUserTableType.DEFAULT;
  @Input() clearStorage = true;
  @Input() isSaved$ = of(false);
  @Input() autoSuggestProperties: IInvolvedUserAutoSuggestProperties;
  @Input() autoSuggestSpinnerShowing = false;
  @Input() autoSuggestEntries: IAutoSuggestUser[];
  @Input() isFormSubmitted = false;
  @Input() showDeleteButton = true;
  @Input() showInheritToAgendaItem = false;
  @Input() disableInheritToAgendaItem = false;
  @Input() titleI18nKey = 'involvedUserTable.title';
  @Input() isFormValid$ = of(false);
  @Input() objectType: string;
  @Input() sortDefaultField = EInvolvedUserTableHeader.SUBJECT_AREA;
  @Input() GridColumnDefinition: GridColumnDefinition[];
  @Input() overrideColumnDefinitions: ColumnDefinition[];
  @Output() getColumnDefinitions: EventEmitter<IColumnDefinition[]> = new EventEmitter<IColumnDefinition[]>();
  @Output() updateUsers = new EventEmitter<InvolvedUser[]>();
  @Output() removeUser = new EventEmitter<InvolvedUser>();
  @Output() addUserEvent = new EventEmitter<void>();
  @Output() userListChange = new EventEmitter();
  @Output() updateSearchTerm = new EventEmitter<string>();

  constructor(
    private readonly userService: UserService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<Recall2AddNewUserComponent>,
  ) {}

  ngOnInit(): void {
    if (!this.userList) {
      this.userList = [];
    }
    this.tempUserList = [...this.userList];
    if (this.userService.latestSavedUserInstance) {
      const savedUser = { ...this.userService.latestSavedUserInstance, isEditable: true, role: this.EXPERT };
      this.tempUserList = [savedUser, ...this.tempUserList];
      this.userService.latestSavedUserInstance = undefined;
      this.isAddAllowed = true;
      this.emitCurrentInvolvedUsersList();
    }

    this.columnDefinitions =
      this.tableType === EInvolvedUserTableType.EXTENDED
        ? getExtendedColumnDefinitions(
            this.autoSuggestProperties,
            this.disableTranscriberCheckBox,
            this.disableVoteCheckBox,
            this.showTranscriberCheckbox,
            this.showVoteCheckBox,
            this.showInheritToAgendaItem,
            this.disableInheritToAgendaItem,
            this.showDeleteButton,
            this.objectType,
          )
        : getDefaultColumnDefinitions(this.autoSuggestProperties, this.showDeleteButton);

    if (this.attendanceColumn) {
      this.columnDefinitions = [...getDefaultColumnDefinitions(undefined, this.showDeleteButton, 'col-md-1')];
      this.columnDefinitions.splice(5, 0, this.attendanceColumn);
    }

    if (this.overrideColumnDefinitions && this.columnDefinitions.length > 0) {
      this.columnDefinitions = this.overrideColumnDefinitions;
    }

    this.tableFacade = new Recall2TableFacadeBuilder('Involved-User-Table', '', true)
      .withColumnDefinition(this.columnDefinitions)
      .withSort(this.defaultSorting)
      .build();

    this.tableFacade.getTableService().setTableData(this.tempUserList);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngAfterViewInit(): void {
    this.getColumnDefinitions.next(this.columnDefinitions);

    const deleteEvent = this.tableFacade
      .getTableService()
      .getCellEvent<InvolvedUser>(DeleteIconCell.selector, DeleteIconCell.removeEvent);

    deleteEvent.pipe(takeUntil(this.destroyed$)).subscribe(user => {
      this.removeUserFromList(user);
    });

    const subjectAreaEvent = this.tableFacade
      .getTableService()
      .getCellEvent<InputTextProperty>(SubjectAreaEditCell.selector, SubjectAreaEditCell.updateSubjectAreaEvent);

    subjectAreaEvent.pipe(takeUntil(this.destroyed$)).subscribe(subjectAreaInput => {
      this.updateSubjectArea(subjectAreaInput);
    });

    const updateRowEvent = this.tableFacade
      .getTableService()
      .getCellEvent<InvolvedUser>(AutoSuggestCell.selector, AutoSuggestCell.updateEditableRowEvent);

    updateRowEvent.pipe(takeUntil(this.destroyed$)).subscribe(data => {
      this.updateEditableRow(data);
    });

    const addUserRowEvent = this.tableFacade
      .getTableService()
      .getCellEvent<InvolvedUser>(AutoSuggestCell.selector, AutoSuggestCell.addNewUserEvent);

    addUserRowEvent.pipe(takeUntil(this.destroyed$)).subscribe(data => {
      this.onAddNewUser(data);
    });

    const updateSearchTerm = this.tableFacade
      .getTableService()
      .getCellEvent<string>(AutoSuggestCell.selector, AutoSuggestCell.updateSearchTerm);

    updateSearchTerm.pipe(takeUntil(this.destroyed$)).subscribe(searchTerm => {
      if (searchTerm !== undefined) {
        this.updateSearchTerm.emit(searchTerm);
      }
    });
    const selectedEvent = this.tableFacade
      .getTableService()
      .getCellEvent<EThreeStepCheckboxStates>(ThreeStepCellComponent.selector, ThreeStepCellComponent.selected);

    selectedEvent.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      if (value) {
        this.emitCurrentInvolvedUsersList(false);
      }
    });

    this.isSaved$.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      if (value) {
        this.onSaveClicked();
      }
    });

    this.isFormValid$.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      if (value) {
        this.openModal();
      }
    });

    this.handleEntitledToVoteChanges();
    this.handleCommitteeTranscriberChanges();
    if (this.showInheritToAgendaItem) {
      this.handleInheritToAgendaItemChanges();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    let userList: InvolvedUser[] = [];
    if (changes.userList) {
      if (this.tableFacade) {
        this.defaultSorting = [];
        this.tableFacade.getSortService().initializeSorting(this.defaultSorting);
      }
      userList = changes.userList.currentValue as InvolvedUser[];
    }
    if (changes.autoSuggestEntries?.currentValue) {
      this.updateAutoSuggestEntries(changes.autoSuggestEntries.currentValue);
    }
    if (changes.autoSuggestSpinnerShowing?.currentValue !== undefined) {
      this.updateAutoSuggestSpinner(changes.autoSuggestSpinnerShowing.currentValue);
    }

    // sort arrays by id, so we can check if the array are equal
    this.oldUser = this.sortByVwUserId(this.oldUser);
    userList = this.sortByVwUserId(userList);

    // on adding new user reset the flag
    // on updating the user list from parent update the table
    if (this.addingNewUser) {
      this.addingNewUser = false;
    } else if (
      this.tableFacade &&
      Array.isArray(userList) &&
      userList.length > 0 &&
      !compareObjects(this.oldUser, userList)
    ) {
      this.tempUserList = [...this.userList];
      this.tableFacade.getTableService().setTableData(this.tempUserList);
      this.isAddAllowed = true;
    }
    this.oldUser = cloneDeep(userList);
  }

  updateAutoSuggestEntries(newEntries: IAutoSuggestUser[]): void {
    this.tableFacade.getTableService().triggerCellEvent(AutoSuggestCell.selector, AutoSuggestCell.entries, newEntries);
  }

  updateAutoSuggestSpinner(isSpinnerShowing: boolean): void {
    if (this.tableFacade?.getTableService().getCellEvent(AutoSuggestCell.selector, AutoSuggestCell.isSpinnerShowing)) {
      this.tableFacade
        .getTableService()
        .triggerCellEvent(AutoSuggestCell.selector, AutoSuggestCell.isSpinnerShowing, isSpinnerShowing);
    }
  }

  onUserListChange(users: InvolvedUser[]): void {
    this.userList = users;
    this.userListChange.emit(users);
  }
  addNewEditRow(): void {
    if (!this.isAddAllowed) {
      return;
    }

    this.addingNewUser = true;

    if (this.tempUserList.length === 0 || !this.tempUserList[0].isEditable) {
      this.isAddAllowed = false;
      this.subjectAreaInput.control.setValue('');

      if (
        Boolean(this.autoSuggestProperties) &&
        Boolean(this.autoSuggestProperties.configuration) &&
        Boolean(this.autoSuggestProperties.configuration.property)
      ) {
        this.autoSuggestProperties.configuration.property.control.setValue('');
      }

      let newUser: InvolvedUser = {
        vwUserId: '',
        firstName: '',
        lastName: '',
        department: '',
        tel: '',
        email: '',
        subjectArea: '',
        role: 'EXPERT',
        isEditable: true,
      };
      if (this.showInheritToAgendaItem) {
        newUser = { ...newUser, syncAgendaItem: true };
      }
      if (this.attendanceColumn) {
        newUser = { ...newUser, attended: EThreeStepCheckboxStates.UNSELECTED };
      }
      this.tempUserList = [newUser, ...this.tempUserList];
    } else {
      if (this.tempUserList[0].vwUserId) {
        this.isAddAllowed = true;
        this.tempUserList[0].subjectArea = this.subjectAreaInput.control.value;
        this.tempUserList[0].isEditable = false;
        this.addNewEditRow();
      }
    }
    this.emitCurrentInvolvedUsersList();
  }

  handleMaster(newValue: EThreeStepCheckboxStates): void {
    for (const user of this.tempUserList) {
      if (user) {
        user.attended = newValue;
      }
    }
    this.emitCurrentInvolvedUsersList(false);
  }

  private sortByVwUserId(list: InvolvedUser[]): InvolvedUser[] {
    if (!list || list.length === 0) {
      return;
    }
    return cloneDeep(list).sort((a: InvolvedUser, b: InvolvedUser) => {
      if (a.vwUserId > b.vwUserId) {
        return 1;
      }

      if (b.vwUserId > a.vwUserId) {
        return -1;
      }

      return 0;
    });
  }

  private handleEntitledToVoteChanges(): void {
    const entitledToVoteChangeEvent = this.tableFacade
      .getTableService()
      .getCellEvent<InvolvedUser>(EntitledToVoteCell.selector, EntitledToVoteCell.changeEvent);

    entitledToVoteChangeEvent.pipe(takeUntil(this.destroyed$)).subscribe(changedUser => {
      if (changedUser) {
        const changedUserIndex = this.tempUserList.findIndex((user: InvolvedUser) => user.id === changedUser.id);

        this.tempUserList[changedUserIndex].entitledToVote = changedUser.entitledToVote;
        this.emitCurrentInvolvedUsersList();
      }
    });
  }

  private handleCommitteeTranscriberChanges(): void {
    const committeeTranscriberChangeEvent = this.tableFacade
      .getTableService()
      .getCellEvent<InvolvedUser>(CommitteeTranscriberCell.selector, CommitteeTranscriberCell.changeEvent);

    committeeTranscriberChangeEvent.pipe(takeUntil(this.destroyed$)).subscribe(changedUser => {
      if (changedUser) {
        const changedUserIndex = this.tempUserList.findIndex(
          (user: InvolvedUser) => user.vwUserId === changedUser.vwUserId,
        );

        this.tempUserList[changedUserIndex].committeeTranscriber = changedUser.committeeTranscriber;
        this.emitCurrentInvolvedUsersList();
      }
    });
  }

  private handleInheritToAgendaItemChanges(): void {
    const inheritToAgendaItemChangeEvent = this.tableFacade
      .getTableService()
      .getCellEvent<InvolvedUser>(InheritToAgendaItemCell.selector, InheritToAgendaItemCell.changeEvent);

    inheritToAgendaItemChangeEvent.pipe(takeUntil(this.destroyed$)).subscribe(changedUser => {
      if (changedUser) {
        const changedUserIndex = this.tempUserList.findIndex((user: InvolvedUser) => user.id === changedUser.id);

        this.tempUserList[changedUserIndex].syncAgendaItem = changedUser.syncAgendaItem;
        this.emitCurrentInvolvedUsersList();
      }
    });
  }
  private updateSubjectArea(subjectAreaInput: InputTextProperty): void {
    if (subjectAreaInput) {
      this.subjectAreaInput = subjectAreaInput;
      this.tempUserList[0].subjectArea = subjectAreaInput.control.value.trim();
      this.emitCurrentInvolvedUsersList();
    }
  }

  private emitCurrentInvolvedUsersList(refreshTable = true): void {
    if (this.tempUserList) {
      const usersToBeEmitted = this.removeEmptyUser(this.tempUserList);
      this.updateUsers.emit(usersToBeEmitted);
      this.onUserListChange(usersToBeEmitted);
      if (refreshTable && this.tableFacade) {
        this.tableFacade.getTableService().setTableData(this.tempUserList);
      }
    }
  }

  private removeEmptyUser(usersList: InvolvedUser[] = this.tempUserList): InvolvedUser[] {
    const tempUsers = [...usersList];
    if (tempUsers && tempUsers[0] && !tempUsers[0].vwUserId) {
      tempUsers.splice(0, 1);
    }
    return [...tempUsers];
  }

  private updateEditableRow(involvedUser: InvolvedUser): void {
    if (involvedUser) {
      this.tempUserList[0].vwUserId = involvedUser.vwUserId;
      this.tempUserList[0].firstName = involvedUser.firstName;
      this.tempUserList[0].lastName = involvedUser.lastName;
      this.tempUserList[0].department = involvedUser.department;
      this.tempUserList[0].tel = involvedUser.tel;
      this.tempUserList[0].email = involvedUser.email;
      this.tempUserList[0].id = involvedUser.id;
      if (this.showInheritToAgendaItem) {
        this.tempUserList[0].syncAgendaItem = true;
      }
      this.emitCurrentInvolvedUsersList();
      this.isAddAllowed = true;
    }
  }

  private removeUserFromList(user: InvolvedUser): void {
    if (this.tempUserList && user) {
      const tempUsers = [...this.tempUserList];
      const index = tempUsers.indexOf(user);
      this.isAddAllowed = true;
      tempUsers.splice(index, 1);
      this.tempUserList = [...tempUsers];
      this.removeUser.emit(user);
      this.emitCurrentInvolvedUsersList();
    }
  }

  private formSubmittedAction(newUser): void {
    this.userService.latestSavedUserInstance = null;
    this.updateEditableRow(newUser);
  }

  private onAddNewUser(data: InvolvedUser): void {
    if (data) {
      this.addUserEvent.emit();
    }
  }

  private openModal(): void {
    const dialogRef = this.dialog.open(Recall2AddNewUserComponent, {
      panelClass: 'add-new-user-modal',
      backdropClass: 'modal-backdrop',
      data: {
        userType: EUserType.USER,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((user: InvolvedUser) => {
        this.formSubmittedAction(user);
      });
  }

  private onSaveClicked(): void {
    this.isAddAllowed = true;
    if (this.tempUserList[0]) {
      delete this.tempUserList[0].isEditable;
    }
    this.tempUserList = this.removeEmptyUser(this.tempUserList);
    this.emitCurrentInvolvedUsersList();
  }
}
