import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import type { AbstractControl, FormGroup } from '@angular/forms';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import type { BrandManufacturer } from '@recall2/ui/brand-manufacturer';
import type { DateProperty, InputTextProperty, ThreeStepCheckboxProperty } from '@recall2/ui/form/model';
import { ELabelAlignment, EThreeStepCheckboxStates } from '@recall2/ui/form/model';
import { ThreeStepCheckboxValidators } from '@recall2/ui/form/validators';
import { cloneDeep, compareObjects } from '@recall2/ui/utils';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import type { INotification } from '../../../models/notification/notification';
import { MeasureFormField } from '../model/measureRequiredFields';
import { DataService } from '../service/data/data.service';
import { ValidationService } from '../service/validation/validation.service';

@Component({
  selector: 'app-notification-measure',
  templateUrl: './notification-measure.component.html',
  styleUrls: ['./notification-measure.component.scss'],
})
export class NotificationMeasureComponent implements OnInit, OnDestroy {
  public readonly src = 'notification-measure.component';

  public readonly MeasureFormField = MeasureFormField;

  public readonly labelAlignment = ELabelAlignment;
  public readonly threeStepCheckboxStates = EThreeStepCheckboxStates;

  public registerForm: FormGroup;

  public fieldProperties = {
    [MeasureFormField.DescriptionMeasureProd]: {
      name: MeasureFormField.DescriptionMeasureProd,
      required: true,
      translationKey: 'notifications.form.measure.series.description',
      htmlValidators: { maxLength: 1024 },
      hasTooltip: true,
      control: new FormControl('', Validators.required),
      hasTitle: true,
    } as InputTextProperty,

    [MeasureFormField.ImplementationDate]: {
      name: MeasureFormField.ImplementationDate,
      required: true,
      translationKey: 'notifications.form.measure.series.implemented',
      hasTooltip: false,
      control: new FormControl('', Validators.required),
      hasTitle: true,
      minDate: undefined,
      maxDate: undefined,
    } as DateProperty,

    [MeasureFormField.DescriptionMeasureAfterSales]: {
      name: MeasureFormField.DescriptionMeasureAfterSales,
      required: true,
      translationKey: 'notifications.form.measure.service.description',
      htmlValidators: { maxLength: 1024 },
      hasTooltip: true,
      control: new FormControl('', Validators.required),
      hasTitle: true,
    } as InputTextProperty,

    [MeasureFormField.AvailabilityDate]: {
      name: MeasureFormField.AvailabilityDate,
      required: true,
      translationKey: 'notifications.form.measure.service.available',
      hasTooltip: false,
      control: new FormControl('', Validators.required),
      hasTitle: true,
      minDate: undefined,
      maxDate: undefined,
    } as DateProperty,

    [MeasureFormField.WarehouseUpdateNumber]: {
      name: MeasureFormField.WarehouseUpdateNumber,
      required: true,
      translationKey: 'notifications.form.measure.warehouseNumber',
      htmlValidators: { maxLength: 64 },
      hasTooltip: true,
      control: new FormControl('', Validators.required),
      hasTitle: true,
    } as InputTextProperty,

    [MeasureFormField.Supplier]: {
      name: MeasureFormField.Supplier,
      required: false,
      translationKey: 'notifications.form.measure.supplierName',
      htmlValidators: { maxLength: 128 },
      hasTooltip: false,
      control: new FormControl('', Validators.required),
      hasTitle: true,
    } as InputTextProperty,

    [MeasureFormField.MeasureImplemented]: {
      name: MeasureFormField.MeasureImplemented,
      required: true,
      translationKey: 'notifications.form.measure.series.isImplemented',
      hasTooltip: false,
      control: new FormControl('', ThreeStepCheckboxValidators.requiredSelectedOrDeselected()),
      hasLabel: true,
    } as ThreeStepCheckboxProperty,

    [MeasureFormField.MeasureAvailable]: {
      name: MeasureFormField.MeasureAvailable,
      required: true,
      translationKey: 'notifications.form.measure.service.isAvailable',
      hasTooltip: false,
      control: new FormControl('', ThreeStepCheckboxValidators.requiredSelectedOrDeselected()),
      hasLabel: true,
    } as ThreeStepCheckboxProperty,

    [MeasureFormField.WarehouseUpdated]: {
      name: MeasureFormField.WarehouseUpdated,
      required: true,
      translationKey: 'notifications.form.measure.warehouseNumber.cleanup',
      hasTooltip: false,
      control: new FormControl('', ThreeStepCheckboxValidators.requiredSelectedOrDeselected()),
      hasLabel: true,
    } as ThreeStepCheckboxProperty,

    [MeasureFormField.SupplierError]: {
      name: MeasureFormField.SupplierError,
      required: false,
      translationKey: 'notifications.form.measure.supplierError',
      hasTooltip: false,
      control: new FormControl(''),
      hasLabel: true,
    } as ThreeStepCheckboxProperty,
  };

  public currentFieldErrors$: Observable<Map<string, boolean>> = this.validationService.currentErrorHandlingMeasure$;

  private currentNotification$: Observable<INotification> = this.formData.currentNotification$;
  public currentNotification: INotification;

  private destroyed$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private formData: DataService,
    private validationService: ValidationService,
  ) {}

  public ngOnInit(): void {
    this.createForm();

    this.setupValueChangeHandling();

    this.currentNotification$
      .pipe(
        takeUntil(this.destroyed$),
        filter(currentNotification => !compareObjects(currentNotification, this.currentNotification)),
      )
      .subscribe((currentNotification: INotification) => {
        this.currentNotification = cloneDeep(currentNotification);
        this.fillFormFields(this.currentNotification);
      });
  }

  private createForm(): void {
    this.registerForm = this.formBuilder.group({
      [MeasureFormField.DescriptionMeasureProd]: this.getFieldControl(MeasureFormField.DescriptionMeasureProd),
      [MeasureFormField.DescriptionMeasureAfterSales]: this.getFieldControl(
        MeasureFormField.DescriptionMeasureAfterSales,
      ),
      [MeasureFormField.WarehouseUpdateNumber]: this.getFieldControl(MeasureFormField.WarehouseUpdateNumber),
      [MeasureFormField.Supplier]: this.getFieldControl(MeasureFormField.Supplier),
      [MeasureFormField.AvailabilityDate]: this.getFieldControl(MeasureFormField.AvailabilityDate),
      [MeasureFormField.MeasureImplemented]: this.getFieldControl(MeasureFormField.MeasureImplemented),
      [MeasureFormField.ImplementationDate]: this.getFieldControl(MeasureFormField.ImplementationDate),
      [MeasureFormField.WarehouseUpdated]: this.getFieldControl(MeasureFormField.WarehouseUpdated),
      [MeasureFormField.MeasureAvailable]: this.getFieldControl(MeasureFormField.MeasureAvailable),
      [MeasureFormField.SupplierError]: this.getFieldControl(MeasureFormField.SupplierError),
      [MeasureFormField.BrandManufacturers]: new FormControl(''),
      searchedBrand: [''],
    });
  }

  private getFieldControl(fieldName: string): AbstractControl {
    return this.fieldProperties[fieldName].control;
  }

  private setupValueChangeHandling(): void {
    Object.values(MeasureFormField).forEach((formField: string) => {
      this.registerForm
        .get(formField)
        .valueChanges.pipe(takeUntil(this.destroyed$))
        .subscribe(value => {
          this.currentNotification[formField] = value;

          this.updateNotification();
        });
    });
  }

  private fillFormFields(currentNotification: INotification): void {
    Object.values(MeasureFormField).forEach((formField: string) => {
      this.registerForm.get(formField).setValue(currentNotification[formField], { emitEvent: false });
    });
  }

  private updateNotification(): void {
    this.formData.updateCurrentNotification(this.currentNotification);
  }

  public updateBrandManufacturers(brandManufacturers: BrandManufacturer[]): void {
    this.currentNotification.brandManufacturers = brandManufacturers;
    this.updateNotification();
  }

  public updateNotificationSupplierErrorUpdated(): void {
    if (this.getFieldControl(MeasureFormField.SupplierError).value !== EThreeStepCheckboxStates.SELECTED) {
      this.getFieldControl(MeasureFormField.Supplier).setValue(null);
    }
  }

  public updateNotificationWarehouseUpdated(): void {
    if (this.getFieldControl(MeasureFormField.WarehouseUpdated).value !== EThreeStepCheckboxStates.SELECTED) {
      this.getFieldControl(MeasureFormField.WarehouseUpdateNumber).setValue(null);
    }
  }

  public updateNotificationMeasureImplemented(): void {
    if (this.getFieldControl(MeasureFormField.MeasureImplemented).value !== EThreeStepCheckboxStates.SELECTED) {
      this.getFieldControl(MeasureFormField.ImplementationDate).setValue(null);
    }
  }

  public updateNotificationMeasureAvailable(): void {
    if (this.getFieldControl(MeasureFormField.MeasureAvailable).value !== EThreeStepCheckboxStates.SELECTED) {
      this.getFieldControl(MeasureFormField.AvailabilityDate).setValue(null);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
