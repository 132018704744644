import type { OnInit } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import type { IReCall2FormProperty } from '@recall2/ui/form/model';
import { InputTextProperty } from '@recall2/ui/form/model';
import { ReCall2FormCancelModalComponent } from '@recall2/ui/overlay';

import type { InvolvedUser } from '../../../model/involved-user.model';
import type { IUser } from '../../../model/user.model';
import { EUserType } from '../../../model/user.model';
import { UserService } from '../../../user.service';
import { NewUserModalData } from '../../models/new-user-modal-data';

@Component({
  selector: 'recall2-add-new-user',
  templateUrl: './recall2-add-new-user.component.html',
  styleUrls: ['./recall2-add-new-user.component.scss'],
})
export class Recall2AddNewUserComponent implements OnInit {
  @Input() userCreate = true;
  @Input() userType;
  isFormSubmitted = false;
  // for loading animation
  isAnimation = false;

  vwUserIdProperty = new InputTextProperty(
    'vwUserId',
    true,
    'user.form.vwUserId',
    { maxLength: 25 },
    false,
    new FormControl('', [Validators.required]),
  );
  firstNameProperty = new InputTextProperty(
    'firstName',
    true,
    'user.form.firstName',
    { maxLength: 25 },
    false,
    new FormControl('', [Validators.required]),
  );
  lastNameProperty = new InputTextProperty(
    'lastName',
    true,
    'user.form.lastName',
    { maxLength: 25 },
    false,
    new FormControl('', [Validators.required]),
  );
  emailProperty = new InputTextProperty(
    'email',
    true,
    'user.form.email',
    { maxLength: 100 },
    false,
    new FormControl('', [Validators.required, Validators.email]),
  );
  phoneProperty = new InputTextProperty(
    'tel',
    true,
    'user.form.phone',
    { maxLength: 45 },
    false,
    new FormControl('', [Validators.required]),
  );
  departmentProperty = new InputTextProperty(
    'department',
    true,
    'user.form.department',
    { maxLength: 45 },
    false,
    new FormControl('', [Validators.required]),
  );
  VERIFICATION_TASK_EDITOR = 'VERIFICATION_TASK_EDITOR';
  private _propertyHolder: IReCall2FormProperty[] = [];
  private _newUser: IUser = { vwUserId: '', firstName: '', lastName: '', department: '', email: '', tel: '' };

  constructor(
    public dialogRef: MatDialogRef<Recall2AddNewUserComponent>,
    private userService: UserService,
    private modal: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: NewUserModalData,
  ) {}

  ngOnInit(): void {
    this._propertyHolder.push(
      this.firstNameProperty,
      this.lastNameProperty,
      this.vwUserIdProperty,
      this.emailProperty,
      this.phoneProperty,
      this.departmentProperty,
    );
    if (this.userService.latestSavedUserInstance) {
      this.vwUserIdProperty.control.setValue(this.userService.latestSavedUserInstance.vwUserId);
      this.firstNameProperty.control.setValue(this.userService.latestSavedUserInstance.firstName);
      this.lastNameProperty.control.setValue(this.userService.latestSavedUserInstance.lastName);
      this.departmentProperty.control.setValue(this.userService.latestSavedUserInstance.department);
      this.emailProperty.control.setValue(this.userService.latestSavedUserInstance.email);
      this.phoneProperty.control.setValue(this.userService.latestSavedUserInstance.tel);
    }
  }

  onClose(): void {
    if (this.hasFormChanges()) {
      this.openDestructiveModal();
    } else {
      if (this.data.closeCallback) {
        this.data.closeCallback();
      }
      this.dialogRef.close(false);
    }
  }

  onSubmit($event): void {
    $event.stopPropagation();
    this.isFormSubmitted = true;
    this.isAnimation = true;
    if (this.isFormValid()) {
      for (const aHolder of this._propertyHolder) {
        this._newUser[aHolder.name] = aHolder.control.value;
      }
      if (this.userCreate) {
        if (this.data.userType === EUserType.EDITOR) {
          const newUserEditor: InvolvedUser = { ...this._newUser, roles: [] };
          newUserEditor.roles.push(this.VERIFICATION_TASK_EDITOR);
          sessionStorage.setItem('newUserEditor', JSON.stringify(newUserEditor));
          this.userService.createUserEditor$(newUserEditor).subscribe(
            () => {
              this.closeModal(this._newUser);
            },
            () => {
              this.isAnimation = false;
            },
          );
        } else {
          this.userService.createUser$(this._newUser).subscribe(
            () => {
              this.userService.latestSavedUserInstance = this._newUser;
              this.closeModal(this._newUser);
            },
            () => {
              this.isAnimation = false;
            },
          );
        }
      } else {
        this.userService.updateUser$(this._newUser).subscribe(
          () => {
            this.userService.latestSavedUserInstance = this._newUser;
            this.closeModal(this._newUser);
          },
          () => {
            this.isAnimation = false;
          },
        );
      }
    }
    this.isAnimation = false;
  }

  private openDestructiveModal(): void {
    this.modal.open(ReCall2FormCancelModalComponent, {
      panelClass: 'form-cancel-modal',
      backdropClass: 'modal-backdrop',
      data: {
        submitCallback: this.closeAddNewUserModal.bind(this),
      },
    });
  }

  private closeAddNewUserModal(): void {
    this.dialogRef.close();
  }

  private closeModal(newUser): void {
    this.dialogRef.close(newUser);
  }

  private isFormValid(): boolean {
    let isValid = true;
    for (const aHolder of this._propertyHolder) {
      if (aHolder.control.invalid) {
        isValid = false;
      }
    }
    return isValid;
  }

  private hasFormChanges(): boolean {
    let hasFormChanges = false;
    for (const aHolder of this._propertyHolder) {
      if (aHolder.control.dirty && aHolder.control.value.trim() !== '') {
        hasFormChanges = true;
      }
    }
    return hasFormChanges;
  }

  get newUser(): IUser {
    return this._newUser;
  }

  set newUser(value: IUser) {
    this._newUser = value;
  }
}
