import type { OnDestroy, OnInit } from '@angular/core';
import { Component, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import type { BrandManufacturer } from '@recall2/ui/brand-manufacturer';
import { CommentsAndHistoryRefreshable } from '@recall2/ui/comments-history';
import { SelectOption, SelectProperty } from '@recall2/ui/form/model';
import { ReCall2FormCancelModalComponent } from '@recall2/ui/overlay';
import { iif, of, Subject } from 'rxjs';
import { map, mergeMap, switchMap, take, takeUntil } from 'rxjs/operators';

import { BrandService } from '../../../../rest-api/brand/brand.service';
import { NotificationService } from '../../../../rest-api/notification/notification.service';
import { pathParamNotificationId } from '../../../routing/notification-routes.constants';
import { NotificationRoutingService } from '../../../routing/notification-routing.service';

@Component({
  selector: 'notification-approve',
  templateUrl: './notification-approve.component.html',
  styleUrls: ['./notification-approve.component.scss'],
})
export class NotificationApproveComponent implements OnInit, OnDestroy {
  pushedSubmit = false;
  @ViewChild('commentsAndHistoryComponent', { static: true })
  commentsAndHistoryComponent: CommentsAndHistoryRefreshable;

  notificationId$ = this.activatedRoute.params.pipe(
    map(params => Number.parseInt(params[pathParamNotificationId], 10)),
  );

  private brandManufacturers: BrandManufacturer[] = [];
  private destroyed$ = new Subject<void>();
  comment: string;
  selectManufacturerProperty: SelectProperty = new SelectProperty(
    'manufacturerSelect',
    false,
    '',
    [],
    false,
    new FormControl(),
    false,
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private brandService: BrandService,
    private modal: MatDialog,
    private routingService: NotificationRoutingService,
    private notificationService: NotificationService,
  ) {}

  onClose(): void {
    this.modal.open(ReCall2FormCancelModalComponent, {
      panelClass: 'recall2-form-cancel-modal',
      backdropClass: 'modal-backdrop',
      data: {
        submitCallback: () => {
          this.withNotificationId(notificationId =>
            this.routingService.navigateToBaseRouteNotification(notificationId),
          );
        },
        title: this.translate.instant('notifications.form.releaseModal.title'),
      },
    });
  }

  setComment(comment: string): void {
    this.comment = comment;
  }

  async onSubmit(): Promise<void> {
    this.pushedSubmit = true;

    if (!this.selectManufacturerProperty.control.value) {
      return;
    }
    this.withNotificationId(notificationId => {
      this.notificationService
        .approveNotification(notificationId, this.selectManufacturerProperty.control.value, this.comment)
        .pipe(mergeMap(() => this.routingService.navigateToOverviewAndHighlight(notificationId)))
        .subscribe();
    });
  }

  ngOnInit(): void {
    this.withNotificationId(notificationId => {
      this.notificationService
        .getNotificationForId(notificationId)
        .pipe(
          takeUntil(this.destroyed$),
          switchMap(currentNotification => {
            return iif(
              () => currentNotification.assignedBrandManufacturers.length > 0,
              of(currentNotification.assignedBrandManufacturers),
              this.brandService.getBrandManufacturerForList(currentNotification.createdAt),
            );
          }),
          map(response => {
            this.brandManufacturers = response;

            this.selectManufacturerProperty = new SelectProperty(
              'manufacturerSelect',
              true,
              'notifications.form.select-manufacturer',
              this.brandManufacturers.map(
                manufacturer => new SelectOption(manufacturer.manufacturer.name, `${manufacturer.id}`),
              ),
              false,
              new FormControl('', [Validators.required]),
            );
          }),
        )
        .subscribe();
    });
  }

  private withNotificationId(callback: (notificationId: number) => void): void {
    this.notificationId$.pipe(take(1)).subscribe(callback);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
