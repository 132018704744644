import type { OnDestroy, OnInit } from '@angular/core';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import type {
  AlreadyForwardedManufacturer,
  BrandManufacturer,
  IBrandManufacturerSelectorConfiguration,
  Manufacturer,
} from '@recall2/ui/brand-manufacturer';
import { CommentsAndHistoryRefreshable } from '@recall2/ui/comments-history';
import type { DestructiveAction } from '@recall2/ui/destructive-action';
import { EObjectType } from '@recall2/ui/dynamic-content';
import { ToastService } from '@recall2/ui/toast';
import { Subject } from 'rxjs';
import { first, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { BrandService } from '../../../../rest-api/brand/brand.service';
import { NotificationService } from '../../../../rest-api/notification/notification.service';
import type { IBrandManufacturers } from '../../../models/brand';
import type { ICreatedAt } from '../../../models/notification/notification';
import { pathParamNotificationId } from '../../../routing/notification-routes.constants';
import { NotificationRoutingService } from '../../../routing/notification-routing.service';

@Component({
  selector: 'notification-manufacturer',
  templateUrl: './notification-manufacturer.component.html',
  styleUrls: ['./notification-manufacturer.component.scss'],
})
export class NotificationManufacturerComponent implements OnInit, OnDestroy, DestructiveAction {
  @ViewChild('commmentHistory') commmentHistory: CommentsAndHistoryRefreshable;

  cancelModalTitle = 'notifications.form.forwardBrandModal.title';

  brandManufacturerConfiguration: IBrandManufacturerSelectorConfiguration;

  selectedManufacturers: Manufacturer[] = [];
  comment: string;
  readonly src = 'notification-history.component.ts';
  readonly objectType = EObjectType.NOTIFICATION;
  notificationId: number;
  isFormSubmitted: boolean;
  isForwarding = false;

  isEveryBrandManufacturerForwarded = false;

  private destroy$ = new Subject<void>();
  private notificationDate: ICreatedAt;

  constructor(
    private routingService: NotificationRoutingService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private brandService: BrandService,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(paramMap => {
      this.notificationId = +paramMap.get(pathParamNotificationId);
    });

    this.loadManufacturersData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setComment(comment: string): void {
    this.comment = comment;
  }

  cancelSend(): void {
    this.routingService.navigateToPrecedingAddress(this.notificationId);
  }

  async forwardNotificationToManufacturer(): Promise<void> {
    this.isFormSubmitted = true;

    if (this.selectedManufacturers.length === 0) {
      return;
    }

    this.isForwarding = true;
    await this.brandService
      .getBrandManufacturerForList(this.notificationDate?.createdAt)
      .pipe(
        map(brandManufacturers =>
          this.selectedManufacturers.map(manufacturer =>
            brandManufacturers.find(brandManufacturer => brandManufacturer.manufacturer.id === manufacturer.id),
          ),
        ),
        map(brandManufacturers => brandManufacturers.map(brandManufacturer => brandManufacturer.id)),
        switchMap(brandManufacturerIds =>
          this.notificationService.forwardNotificationToManufacturer(
            this.notificationId,
            brandManufacturerIds,
            this.comment,
          ),
        ),
        tap(() => {
          this.toastService.success({
            title: this.translateService.instant('notifications.form.forward.title.success'),
            content: this.translateService.instant('notifications.form.forward.message.success'),
          });
          this.routingService.navigateToBaseRouteNotification(this.notificationId);
          this.isForwarding = false;
        }),
        first(),
      )
      .toPromise();
  }

  updateManufacturerSelection(manufacturers: Manufacturer[]): void {
    this.selectedManufacturers = manufacturers;
  }

  hasChanges(): boolean {
    return this.isFormSubmitted ? false : this.selectedManufacturers.length > 0;
  }

  saveCurrentFormData(): boolean {
    return true;
  }

  private mergeManufacturers(brandManufacturers: BrandManufacturer[]): IBrandManufacturers[] {
    return brandManufacturers.reduce((list, element) => {
      const existingBrandManufacturer = list.find(brandManufacturer => brandManufacturer.brand.id === element.brand.id);

      if (existingBrandManufacturer) {
        existingBrandManufacturer.manufacturers.push(element.manufacturer);
      } else {
        list.push({
          brand: element.brand,
          manufacturers: [element.manufacturer],
        });
      }

      return list;
    }, []);
  }

  private loadManufacturersData(): void {
    let alreadyForwardedManufacturers: AlreadyForwardedManufacturer[] = [];

    this.notificationService
      .getAlreadyForwardedManufacturers(this.notificationId)
      .pipe(
        takeUntil(this.destroy$),
        switchMap(forwardedManufacturers => {
          alreadyForwardedManufacturers = forwardedManufacturers;

          return this.notificationService.getNotificationDate(this.notificationId).pipe(takeUntil(this.destroy$));
        }),
        switchMap(notificationDate => {
          this.notificationDate = notificationDate;
          return this.brandService.getBrandManufacturerForList(notificationDate.createdAt).pipe(
            first(),
            map(brandManufacturers => this.mergeManufacturers(brandManufacturers)),
            map(brandManufacturers =>
              this.getManufacturerConfiguration(brandManufacturers, alreadyForwardedManufacturers),
            ),
          );
        }),
      )
      .subscribe(brandManufacturers => {
        this.brandManufacturerConfiguration = brandManufacturers;

        this.isEveryBrandManufacturerForwarded = this.isAllBrandManufacturerForwarded();
      });
  }

  private isAllBrandManufacturerForwarded(): boolean {
    const isBrandManufacturersSameSizeAsForwarded =
      this.brandManufacturerConfiguration.alreadyForwardedManufacturers.length ===
      this.brandManufacturerConfiguration.brandManufacturers.length;

    const isEverySubManufacturerForwarded = this.brandManufacturerConfiguration.alreadyForwardedManufacturers.every(
      forwardedManufacturer => forwardedManufacturer.areAllBrandManufacturerForwarded,
    );

    return isBrandManufacturersSameSizeAsForwarded && isEverySubManufacturerForwarded;
  }

  private getManufacturerConfiguration(
    brandManufacturers: IBrandManufacturers[],
    alreadyForwardedManufacturers: AlreadyForwardedManufacturer[],
  ): IBrandManufacturerSelectorConfiguration {
    return {
      translationKeyTitle: '',
      translationKeySubtitle: '',
      defaultManufacturer: {} as Manufacturer,
      brandManufacturers,
      selectedManufacturers: [],
      alreadyForwardedManufacturers,
    };
  }
}
