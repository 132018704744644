import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [HttpClientModule, CommonModule, RouterModule, TranslateModule, MatProgressSpinnerModule],
  exports: [MatProgressSpinnerModule],
  providers: [],
})
export class UIModule {}
