import { ERole } from '@recall2/ui/navbar';

export const getTranslationKeyForSystemUser = (role: string): string => {
  const eRole: ERole = ERole[role];
  switch (eRole) {
    case ERole.REPORTER:
      return 'role.reporter';
    case ERole.REVIEWER:
      return 'role.reviewer';
    case ERole.COORDINATOR_BRAND_OFFICE:
      return 'role.coordinator.brand.officer';
    case ERole.EXPERT:
      return 'role.expert';
    case ERole.COORDINATOR_TOPIC:
      return 'role.coordinator.topic';
    case ERole.COORDINATOR_CLEARING:
      return 'role.coordinator.clearing';
    case ERole.COMMITTEE_MEMBER:
      return 'role.committee.member';
    case ERole.COMMITTEE_TRANSCRIBER:
      return 'role.committee.transcriber';
    case ERole.VERIFICATION_TASK_EDITOR:
      return 'role.verification.task.editor';
    case ERole.CAMPAIGN_AUTHOR:
      return 'role.campaign.author';
    case ERole.CAMPAIGN_COORDINATOR_BRAND:
      return 'role.campaign.coordinator.brand';
    case ERole.ADMIN:
      return 'role.admin';
    default:
      return 'role.default';
  }
};
