import type { AfterViewInit, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AbstractGenericCellViewWithData } from '@recall2/ui/dynamic-content';
import type { CheckboxProperty } from '@recall2/ui/form/model';
import type { Recall2TableService, WithTableService } from '@recall2/ui/tables';

import type { InvolvedUser } from '../../../model/involved-user.model';

@Component({
  selector: 'entitled-to-vote-cell',
  templateUrl: './entitled-to-vote.cell.html',
  styleUrls: ['./entitled-to-vote.cell.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class EntitledToVoteCell
  extends AbstractGenericCellViewWithData<InvolvedUser>
  implements WithTableService, AfterViewInit, OnInit
{
  static readonly selector = 'recall2-involved-user-entitled-to-vote-cell';
  static readonly changeEvent = 'onChange';

  checkboxProperty: CheckboxProperty = {
    name: 'entitledToVoteCheckbox',
    required: false,
    translationKey: undefined,
    isChecked: false,
    hasTooltip: false,
    control: new FormControl(),
  };

  private tableService: Recall2TableService;
  private disableCheckBox: boolean;

  ngOnInit(): void {
    if (this.data.entitledToVote) {
      this.updateCheckboxValue();
    }
  }

  ngAfterViewInit(): void {
    if (this.disableCheckBox) {
      this.checkboxProperty.control.disable();
    }
    if (this.data.entitledToVote) {
      this.updateCheckboxValue();
    }
  }

  setTableService(tableService: Recall2TableService): void {
    this.tableService = tableService;
    this.tableService.registerCellEvent<InvolvedUser>(EntitledToVoteCell.selector, EntitledToVoteCell.changeEvent);
  }

  updateCheckboxValue(): void {
    this.checkboxProperty.isChecked = this.data.entitledToVote;
    this.checkboxProperty.control.setValue(this.data.entitledToVote);
    this.checkboxProperty.control.updateValueAndValidity();
  }

  onChange(control: FormControl): void {
    this.data.entitledToVote = control.value;

    this.tableService.triggerCellEvent<InvolvedUser>(
      EntitledToVoteCell.selector,
      EntitledToVoteCell.changeEvent,
      this.data,
    );
  }
}

export class EntitledToVoteCellDataBinding implements IEntitledToVoteBinding {
  constructor(
    public propertyName?: string,
    public additionalCssClasses?: string[],
    public disableCheckBox?: boolean,
  ) {}
}

interface IEntitledToVoteBinding {
  propertyName?: string;
  additionalCssClasses?: string[];
}
