import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { Recall2ButtonBrandManufacturerComponent } from './recall2-button-brand-manufacturer.component';

@NgModule({
  declarations: [Recall2ButtonBrandManufacturerComponent],
  imports: [CommonModule, TranslateModule],
  exports: [Recall2ButtonBrandManufacturerComponent],
  providers: [],
})
export class Recall2ButtonBrandManufacturerModule {}
