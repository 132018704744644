import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Recall2RadioButtonGroupV2Component } from '@recall2/ui/form/components/recall2-radio-button-group-v2';
import { Recall2IconAddCircleComponent, Recall2IconHelpComponent } from '@recall2/ui/icons';

import { Recall2ButtonBrandManufacturerModule } from './components';
import { LandingPageComponent } from './landing-page.component';

@NgModule({
  declarations: [LandingPageComponent],
  imports: [
    CommonModule,
    TranslateModule,
    Recall2ButtonBrandManufacturerModule,
    Recall2RadioButtonGroupV2Component,
    Recall2IconAddCircleComponent,
    Recall2IconHelpComponent,
  ],
  exports: [LandingPageComponent],
  providers: [],
})
export class LandingPageModule {}
