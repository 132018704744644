import type { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { Component, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  type IAutoSuggestConfiguration,
  type IAutoSuggestUser,
  EAutoSuggestObjectType,
} from '@recall2/ui/auto-suggest';
import { AbstractGenericCellViewWithData } from '@recall2/ui/dynamic-content';
import { InputTextProperty } from '@recall2/ui/form/model';
import type { Recall2TableService, WithTableService } from '@recall2/ui/tables';
import { Subject, takeUntil } from 'rxjs';

import type { IInvolvedUserAutoSuggestProperties, InvolvedUser } from '../../../model/involved-user.model';

@Component({
  selector: 'auto-suggest--cell',
  templateUrl: './auto-suggest-cell.component.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AutoSuggestCell
  extends AbstractGenericCellViewWithData<InvolvedUser>
  implements WithTableService, OnInit, AfterViewInit, OnDestroy
{
  static readonly selector = 'recall2-involved-user-id-name-editable-cell';
  static readonly updateEditableRowEvent = 'onUpdateEditableRow';
  static readonly addNewUserEvent = 'onAddNewUser';
  static readonly updateSearchTerm = 'updateSearchTerm';
  static readonly isSpinnerShowing = 'isSpinnerShowing';
  static readonly entries = 'entries';

  readonly autoSuggestObjectType = EAutoSuggestObjectType;

  isSpinnerShowing = false;
  autoSuggestProperties: IInvolvedUserAutoSuggestProperties;
  configuration: IAutoSuggestConfiguration = {
    property: new InputTextProperty(
      'autoSuggestCell',
      false,
      'autoSuggest',
      { maxLength: 50 },
      false,
      new FormControl('', []),
      false,
    ),
    searchDirectoryEnabled: true,
  };
  dataToSkip: IAutoSuggestUser[] = [];
  entries?: IAutoSuggestUser[];

  private tableService: Recall2TableService<IAutoSuggestUser>;
  private destroyed$ = new Subject<void>();

  constructor(private eleRef: ElementRef) {
    super();
  }

  ngOnInit(): void {
    if (Boolean(this.autoSuggestProperties) && Boolean(this.autoSuggestProperties.configuration)) {
      this.configuration = this.autoSuggestProperties.configuration;
    }
    this.tableService.getTableData().subscribe(data => {
      if (data && data.length > 0) {
        this.dataToSkip = [...data];
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngAfterViewInit(): void {
    const autoSuggestInput = this.eleRef.nativeElement.querySelector('recall2-auto-suggest-input input');
    if (autoSuggestInput && this.data.vwUserId) {
      autoSuggestInput.value = this.data.vwUserId;
    }
    this.tableService
      .getCellEvent<IAutoSuggestUser[]>(AutoSuggestCell.selector, AutoSuggestCell.entries)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(entries => {
        if (entries) {
          this.entries = entries;
        }
      });
    this.tableService
      .getCellEvent<boolean>(AutoSuggestCell.selector, AutoSuggestCell.isSpinnerShowing)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(isSpinnerShowing => {
        this.isSpinnerShowing = isSpinnerShowing;
      });
  }

  updateEditableRow(user: InvolvedUser): void {
    this.tableService.triggerCellEvent<InvolvedUser>(
      AutoSuggestCell.selector,
      AutoSuggestCell.updateEditableRowEvent,
      user,
    );
  }

  onAddNewUser(): void {
    this.tableService.triggerCellEvent<InvolvedUser>(
      AutoSuggestCell.selector,
      AutoSuggestCell.addNewUserEvent,
      this.data,
    );
  }

  setTableService(tableService: Recall2TableService<IAutoSuggestUser>): void {
    this.tableService = tableService;
    this.tableService.registerCellEvent<InvolvedUser>(AutoSuggestCell.selector, AutoSuggestCell.updateEditableRowEvent);
    this.tableService.registerCellEvent<InvolvedUser>(AutoSuggestCell.selector, AutoSuggestCell.addNewUserEvent);
    this.tableService.registerCellEvent<InvolvedUser>(AutoSuggestCell.selector, AutoSuggestCell.updateSearchTerm);
    this.tableService.registerCellEvent<string>(AutoSuggestCell.selector, AutoSuggestCell.entries);
    this.tableService.registerCellEvent<boolean>(AutoSuggestCell.selector, AutoSuggestCell.isSpinnerShowing);
  }

  onSearchTermChanged(searchTerm: string): void {
    this.tableService.triggerCellEvent<string>(AutoSuggestCell.selector, AutoSuggestCell.updateSearchTerm, searchTerm);
  }
}

export class UsernameCellDataBinding implements IUsernameBinding {
  constructor(
    public id: string,
    public firstName: string,
    public lastName: string,
    public autoSuggestProperties?: IInvolvedUserAutoSuggestProperties,
    public replaceData?: any,
  ) {}
}

interface IUsernameBinding {
  id: string;
  firstName: string;
  lastName: string;
}
