import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import type { IAutoSuggestUser } from '@recall2/ui/auto-suggest';
import { InputTextProperty } from '@recall2/ui/form/model';
import type { PageDTO } from '@recall2/ui/pagination';
import { cloneDeep } from '@recall2/ui/utils';
import type { IInvolvedUserAutoSuggestProperties, InvolvedUser } from '@recall2/user';
import type { Observable } from 'rxjs';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { AutoSuggestService } from '@/rest-api/auto-suggest/auto-suggest.service';

import type { IAppState } from '../../../../store/app/app.state';
import type { INotification } from '../../../models/notification/notification';
import { UpdateCurrentNotificationAction } from '../../../store/notification.actions';
import { ValidationService } from '../service/validation/validation.service';
import { DataService } from './../service/data/data.service';
import { NavigationService } from './../service/navigation/navigation.service';

@Component({
  selector: 'app-notification-persons',
  templateUrl: './notification-persons.component.html',
})
export class NotificationPersonsComponent implements OnInit, OnDestroy {
  readonly src = 'notification-persons.component.ts';

  currentNotification: INotification;
  isFormValid$ = new BehaviorSubject(false);
  autoSuggestProperties: IInvolvedUserAutoSuggestProperties = {
    configuration: {
      property: new InputTextProperty(
        'autoSuggestCell',
        false,
        'autoSuggest',
        { maxLength: 50 },
        false,
        new FormControl('', []),
        false,
      ),
      searchDirectoryEnabled: true,
    },
  };
  isSpinnerShowing = false;
  users: IAutoSuggestUser[];
  private currentNotification$: Observable<INotification> = this.formData.currentNotification$;
  private destroyed$ = new Subject<void>();

  constructor(
    private formData: DataService,
    private formNavigation: NavigationService,
    private store: Store<IAppState>,
    private validationService: ValidationService,
    private autoSuggestServiceImpl: AutoSuggestService,
  ) {}

  getCurrentRouteCommands = (): string[] => this.formNavigation.getCurrentFormRouteCommands();

  ngOnInit(): void {
    this.currentNotification$.pipe(takeUntil(this.destroyed$)).subscribe((currentNotification: INotification) => {
      this.currentNotification = cloneDeep(currentNotification);
      this.store.dispatch(new UpdateCurrentNotificationAction(currentNotification));
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  updateInvolvedUser(updated: InvolvedUser[]): void {
    this.currentNotification.involvedUsers = updated;
    this.formData.updateCurrentNotification(this.currentNotification);
  }

  checkIsValid(): void {
    if (this.validationService.isValidForSaving(this.formData._persistedNotification)) {
      this.isFormValid$.next(true);
    }
  }

  onSearchTermChanged(searchTerm: string): void {
    this.isSpinnerShowing = true;
    this.autoSuggestServiceImpl
      .searchUsersByString(searchTerm)
      .pipe(filter(entriesList => !!entriesList))
      .subscribe({
        next: (res: PageDTO<IAutoSuggestUser>) => {
          this.users = res.content;
          this.isSpinnerShowing = false;
        },
      });
  }
}
