import type { OnDestroy, OnInit } from '@angular/core';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ObjectHistoryService } from '@recall2/ui/comments-history';
import type { DestructiveAction } from '@recall2/ui/destructive-action';
import { EObjectType } from '@recall2/ui/dynamic-content';
import { AppTranslationService } from '@recall2/ui/i18n';
import { ERole } from '@recall2/ui/navbar';
import type { Step } from '@recall2/ui/navigation-stepper';
import { ReCall2FormCancelModalComponent } from '@recall2/ui/overlay';
import { TabTitleService } from '@recall2/ui/tab-title';
import type { Observable } from 'rxjs';
import { of, Subject } from 'rxjs';
import { filter, first, map, switchMap, takeUntil } from 'rxjs/operators';

import { AppKnownUserProviderService } from '../../../../routing/known-user-provider/app-known-user-provider.service';
import type { IAppState } from '../../../../store/app/app.state';
import { UserSelectors } from '../../../../user/store/user.selectors';
import type { UserData } from '../../../../user/store/user.state';
import { UserStoreService } from '../../../../user/store/user.store.service';
import { EFormType } from '../../../models/form/formType';
import type { INotification } from '../../../models/notification/notification';
import { NotificationStatus } from '../../../models/notification/notification-status.enum';
import { baseRouteNotification } from '../../../routing/notification-routes.constants';
import { NotificationRoutingService } from '../../../routing/notification-routing.service';
import { SaveCurrentNotificationAction, UpdateCurrentNotificationAction } from '../../../store/notification.actions';
import { NotificationSelectors } from '../../../store/notification.selectors';
import { DataService } from '../service/data/data.service';
import { NavigationService } from '../service/navigation/navigation.service';
import { ValidationService } from '../service/validation/validation.service';
import { DisplayedComponent } from '../slider-content/slider-content.component';

@Component({
  selector: 'app-notification-form',
  templateUrl: './notification-parent.component.html',
  styleUrls: ['./notification-parent.component.scss'],
  providers: [NavigationService, DataService, ValidationService, AppKnownUserProviderService],
})
export class NotificationParentComponent implements OnInit, OnDestroy, DestructiveAction {
  @ViewChild('slider', { static: true }) slider;
  displayedComponent = DisplayedComponent;

  public cancelModalTitle = 'cancelModal.title';

  public readonly src = 'notification-parent.component.ts';

  public isSaving = false;
  public isSending = false;
  public currentLocale = '';

  public currentNavigationSteps$: Observable<Step[]> = this.navigationService.currentNavigationSteps$;
  public currentNotification$: Observable<INotification> = this.dataService.currentNotification$;

  public currentNotification: INotification;

  public displayedSliderComponent: DisplayedComponent;

  public currentUser: UserData;

  public statusContainRevisionGs = false;

  public isNotificationDeleted = false;

  private destroyed$ = new Subject<void>();

  public notificationStatus = NotificationStatus;

  constructor(
    private store: Store<IAppState>,
    private modal: MatDialog,
    private navigationService: NavigationService,
    private dataService: DataService,
    private validationService: ValidationService,
    private knownUserProviderService: AppKnownUserProviderService,
    private objectHistoryService: ObjectHistoryService,
    private tabTitleService: TabTitleService,
    private translationService: AppTranslationService,
    private notificationRouting: NotificationRoutingService,
    private userStoreService: UserStoreService,
  ) {}

  public hasNextFormPage = (): boolean => this.navigationService.hasNextFormPage();
  public getNextFormPage = (): EFormType => this.navigationService.getNextFormPage();
  public jumpToNextPage = (): void => this.navigationService.jumpToNextPage();

  public isSummaryActive = (): boolean => this.navigationService.getActiveFormPage() === EFormType.summary;

  public notificationCanBeAction(status: NotificationStatus): boolean {
    if (this.currentNotification && this.currentUser) {
      return (
        this.currentNotification.status === status &&
        this.currentUser.vwUserId === (this.currentNotification.reporter && this.currentNotification.reporter.vwUserId)
      );
    }
    return false;
  }

  public onCancelForm = (): void => {
    this.knownUserProviderService
      .isKnownUser()
      .pipe(first())
      .subscribe(userState => {
        if (userState.isKnown) {
          this.navigationService.routeToOverview();
        } else {
          this.navigationService.routeToLandingPage();
        }
      });
  };

  public ngOnInit(): void {
    const notificationId = this.navigationService.extractNotificationIdFromPath();
    this.store
      .select(UserSelectors.getUser)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((userData: UserData) => {
        this.currentUser = userData;
      });

    if (notificationId) {
      this.dataService.fetchNotification(notificationId);
      this.dataService.loadAttachments(notificationId);
    } else {
      this.dataService.initializeNewNotification();
    }

    this.subscribeToData();
    this.navigationService.initialize();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public toggleSlider(displayedComponent: DisplayedComponent): void {
    this.displayedSliderComponent = displayedComponent;
    this.slider.toggle();
  }

  public onCancel($event: Event): void {
    $event.stopPropagation();
    this.onCancelForm();
  }

  public onDiscardNotification(): void {
    this.navigationService.discardNotification(this.currentNotification.id);
  }

  public onDeleteNotification(): void {
    this.modal.open(ReCall2FormCancelModalComponent, {
      panelClass: 'form-cancel-modal',
      backdropClass: 'modal-backdrop',
      data: {
        submitCallback: () => this.deleteNotification(),
        title: 'notifications.form.deleteModal.title',
        content: 'notifications.form.deleteModal.content',
      },
    });
  }

  public deleteNotification(): void {
    this.dataService
      .deleteCurrentNotification(this.currentNotification.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.isNotificationDeleted = true;
          this.navigationService.routeToOverview();
        },
      });
  }

  public onReactivateNotification(): void {
    if (this.dataService.hasChanges() && this.validationService.isValidForSaving(this.currentNotification)) {
      this.isSaving = true;
      this.dataService
        .saveCurrentNotification(this.currentNotification)
        .pipe(first())
        .subscribe({
          next: (savedNotification: INotification) => this.reactivateNotification(savedNotification),
          error: () => (this.isSaving = false),
        });
    } else {
      this.reactivateNotification();
    }
  }

  public reactivateNotification(notification: INotification = this.currentNotification): void {
    this.isSaving = true;
    this.dataService
      .reactivateCurrentNotification(notification.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.isSaving = false;
          this.navigationService.routeToOverview();
        },
        error: () => {
          this.isSaving = false;
        },
      });
  }

  public saveNotification(): void {
    if (this.validationService.isValidForSaving(this.currentNotification)) {
      this.isSaving = true;
      this.dataService
        .saveCurrentNotification(this.currentNotification)
        .pipe(first())
        .subscribe(
          savedNotification => {
            return {
              next: this.onSaveSuccess(savedNotification),
            };
          },
          () => (this.isSaving = false),
        );
    }
  }

  private onSaveSuccess(notification: INotification): void {
    this.isSaving = false;
    this.store
      .select(UserSelectors.getUser)
      .pipe(
        first(),
        filter((userData: UserData) => !userData.roles || !userData.roles.includes(ERole.REPORTER)),
        switchMap(() => this.userStoreService.getUser()),
      )
      .subscribe();
    this.redirectToPersistedNotification(notification.id);
  }

  private redirectToPersistedNotification(notificationId: number): void {
    // We replace history to avoid come back to creation from detail, forcing
    // the unsaved changes modal display
    if (this.notificationRouting.isCreteNotification()) {
      history.pushState(null, '', baseRouteNotification);
    }
    this.navigationService.redirectToPersistedNotification(notificationId);
  }

  public onSendNotificationToReview(): void {
    this.isSending = true;
    if (this.validationService.isValidForSending(this.currentNotification)) {
      if (this.dataService.hasChanges()) {
        this.dataService
          .saveCurrentNotification(this.currentNotification)
          .pipe(first())
          .subscribe({
            next: (notification: INotification) => {
              this.store.dispatch(new UpdateCurrentNotificationAction(notification));
              this.navigationService.sendNotification(notification.id);
              this.isSending = false;
            },
            error: () => (this.isSending = false),
          });
      } else {
        this.store.dispatch(new UpdateCurrentNotificationAction(this.currentNotification));
        this.navigationService.sendNotification(this.currentNotification.id);
      }
    } else {
      this.isSending = false;
    }
    this.validationService.enableContinuousValidation(this.currentNotification$);
  }

  private subscribeToData(): void {
    this.currentNotification$.pipe(takeUntil(this.destroyed$)).subscribe((notification: INotification) => {
      this.currentNotification = notification;
      if (notification.id) {
        this.tabTitleService.setTitleByKeyWithIdName(
          'preliminary.notification.tab-title',
          '' + notification.id,
          notification.title,
        );
      } else {
        this.tabTitleService.setTitleByKey('preliminary.create.tab-title');
      }
      if (!!notification.id && this.isSummaryActive()) {
        this.objectHistoryService
          .getItems(EObjectType.NOTIFICATION, notification.id)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(data => {
            this.statusContainRevisionGs = data
              .filter(item => item.type === 'CHANGE_STATUS')
              .some(item => item.paramsJson.status === NotificationStatus.TO_REVISE_BRAND_OFFICE)
              ? true
              : false;
          });
      }
    });

    this.translationService.currentLanguage$.pipe(takeUntil(this.destroyed$)).subscribe(currentLocale => {
      this.currentLocale = currentLocale;
    });
  }

  public sliderTitleKey(): string {
    return this.displayedSliderComponent === DisplayedComponent.Attachment ? 'attachment.title' : 'history.title';
  }

  public hasChanges(): boolean {
    return this.isNotificationDeleted ? false : this.dataService.hasChanges();
  }

  public saveCurrentFormData(): Observable<boolean> {
    if (this.dataService.hasChanges() && this.validationService.isValidForSaving(this.currentNotification)) {
      this.isSaving = true;
      this.store.dispatch(new SaveCurrentNotificationAction(this.currentNotification));
      return this.store.select(NotificationSelectors.getCurrentNotification).pipe(
        filter(notification => !!notification),
        map(() => true),
      );
    } else {
      return of(false);
    }
  }
}
