import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ReCall2CoreModule } from '@recall2/ui/core.module';
import { Recall2IconLogoRecallComponent } from '@recall2/ui/icons';

import { HomePageComponentsModule } from '../components/home-page-components.module';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';

@NgModule({
  declarations: [WelcomePageComponent],
  exports: [WelcomePageComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ReCall2CoreModule,
    RouterModule,
    HomePageComponentsModule,
    Recall2IconLogoRecallComponent,
  ],
})
export class WelcomePageModule {}
