import type { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import type { Step } from '@recall2/ui/navigation-stepper';
import { ValidationStatus } from '@recall2/ui/navigation-stepper';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { EFormType } from '../../../../models/form/formType';
import { ENotificationSendAction } from '../../../../models/notification/send-action.enum';
import type { FormRouteData } from '../../../../models/routes/form-route-data';
import {
  baseRouteNotification,
  pathParamNotificationId,
  routeCreate,
} from '../../../../routing/notification-routes.constants';
import type { FormStepStatus } from './form-step-status.model';

@Injectable()
export class NavigationService implements OnDestroy {
  public readonly pageOrder: Map<EFormType, number> = new Map([
    [EFormType.description, 0],
    [EFormType.measure, 1],
    [EFormType.vehicles, 2],
    [EFormType.persons, 3],
    [EFormType.summary, 4],
  ]);

  public navigationSteps: Step[] = [];
  private formStepStates: FormStepStatus[] = [];

  private routeFormAncestor: string;

  private activeFormPage: EFormType;

  private destroyed$ = new Subject<void>();

  private navigationSource = new BehaviorSubject<Step[]>(this.navigationSteps);
  public currentNavigationSteps$ = this.navigationSource.asObservable();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.pageOrder.forEach((index: number, key: EFormType) => {
      this.navigationSteps[index] = this.createNavigationStep(key);
      this.formStepStates[index] = { formPage: key, status: ValidationStatus.INITIAL };
    });
  }

  private createNavigationStep(formPage: EFormType): Step {
    return {
      translationKey: `notifications.form.category.${formPage}`,
      route: formPage,
      validationStatus: ValidationStatus.INITIAL,
    } as Step;
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public initialize(): void {
    this.setupNavigationEventHandling();

    this.setupRouteAncestor();

    this.applyActiveFormPage(this.extractCurrentPage(this.activatedRoute));
  }

  public setStepStatus(formPage: EFormType, status: ValidationStatus): void {
    const pageIndex = this.pageOrder.get(formPage);
    this.formStepStates[pageIndex].status = status;
  }

  public hasNextFormPage(): boolean {
    const lastPage = [...this.pageOrder].pop();
    const isActiveLastPage = lastPage[0] === this.activeFormPage;
    return !isActiveLastPage;
  }

  public getActiveFormPage(): EFormType {
    return this.activeFormPage;
  }

  public getCurrentFormRouteCommands(): string[] {
    return [baseRouteNotification, this.routeFormAncestor, this.activeFormPage];
  }

  public getNextFormPage(): EFormType {
    if (this.hasNextFormPage()) {
      const currentIndex = this.pageOrder.get(this.activeFormPage);

      let nextFormPage: EFormType;
      this.pageOrder.forEach((value: number, formPage: EFormType) => {
        if (value === currentIndex + 1) {
          nextFormPage = formPage;
        }
      });

      return nextFormPage;
    }
  }

  public jumpToNextPage(): void {
    if (this.hasNextFormPage()) {
      const nextFormPage = this.getNextFormPage();

      this.router.navigate([baseRouteNotification, this.routeFormAncestor, nextFormPage]).then();
      this.activeFormPage = nextFormPage;
    }
  }

  public extractNotificationIdFromPath(): number {
    const notificationId = +this.activatedRoute.snapshot.paramMap.get(pathParamNotificationId);

    return notificationId ? notificationId : null;
  }

  public updateNavigationStepsColoring(): void {
    this.navigationSteps.forEach((step: Step, index: number) => {
      step.validationStatus = this.formStepStates[index].status;
    });

    this.navigationSource.next([...this.navigationSteps]);
  }

  public routeToOverview(): void {
    this.router.navigate([baseRouteNotification]);
  }

  public routeToLandingPage(): void {
    this.router.navigate(['landing']);
  }

  public redirectToPersistedNotification(notificationId: number): void {
    this.router.navigate([baseRouteNotification, notificationId, this.activeFormPage]).then();
  }

  public routeToFirstErrorPage(): void {
    const firstInvalidPageIndex: number = this.formStepStates.findIndex(
      (formStepStatus: FormStepStatus) => formStepStatus.status === ValidationStatus.INVALID,
    );

    if (this.hasNoInvalidPage(firstInvalidPageIndex)) {
      return;
    }

    const invalidPageStep = this.navigationSteps[firstInvalidPageIndex];

    if (invalidPageStep) {
      this.router.navigate([baseRouteNotification, this.routeFormAncestor, invalidPageStep.route]).then();
    }
  }

  private hasNoInvalidPage(foundIndex: number): boolean {
    return foundIndex === -1;
  }

  public sendNotification(notificationId: number): void {
    this.router.navigate([baseRouteNotification, notificationId, ENotificationSendAction.SEND]).then();
  }

  public discardNotification(notificationId: number): void {
    this.router.navigate([baseRouteNotification, notificationId, ENotificationSendAction.DISCARD]).then();
  }

  private setupNavigationEventHandling(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        this.applyActiveFormPage(this.extractCurrentPage(this.activatedRoute));
      });
  }

  private setupRouteAncestor(): void {
    const objectId = this.extractNotificationIdFromPath();
    this.routeFormAncestor = objectId ? objectId.toString() : routeCreate;
  }

  private extractCurrentPage(activatedRoute: ActivatedRoute): EFormType {
    return (activatedRoute.snapshot.firstChild.data as FormRouteData).page;
  }

  private applyActiveFormPage(page: EFormType): void {
    if (this.pageOrder.has(page)) {
      this.activeFormPage = page;
    } else {
      throw new Error(`form navigation does not contain the form page: ${page}`);
    }
  }
}
