import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Recall2ButtonOutlineComponent } from '@recall2/ui/buttons';
import { ReCall2CoreModule } from '@recall2/ui/core.module';
import { Recall2IconAddComponent } from '@recall2/ui/icons';

import { CreateNotificationButtonComponent } from './create-notification-button/create-notification-button.component';

@NgModule({
  declarations: [CreateNotificationButtonComponent],
  exports: [CreateNotificationButtonComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ReCall2CoreModule,
    RouterModule,
    Recall2ButtonOutlineComponent,
    Recall2IconAddComponent,
  ],
})
export class HomePageComponentsModule {}
