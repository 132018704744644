import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import type { AbstractControl, FormGroup } from '@angular/forms';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import type { IPart } from '@recall2/ui/core/models';
import type { InputTextProperty } from '@recall2/ui/form/model';
import { SpecialCharsValidationService } from '@recall2/ui/form/validators';
import type { Observable } from 'rxjs';
import { defer, Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';

import type { INotification } from '../../../models/notification/notification';
import type { NotificationStatus } from '../../../models/notification/notification-status.enum';
import { NotificationRoutingService } from '../../../routing/notification-routing.service';
import { NotificationPermissionService } from '../../../services/notification-permission/notification-permission.service';
import type { IEditableInputTableTranslationConfig } from '../editable-input-table/i-editable-input-table-translation-config';
import { ETableType } from '../editable-input-table/table-type';
import { DescriptionFormField } from '../model/descriptionRequiredFields';
import { DataService } from '../service/data/data.service';
import { ValidationService } from '../service/validation/validation.service';

@Component({
  selector: 'app-notification-description',
  templateUrl: './notification-description.component.html',
})
export class NotificationDescriptionComponent implements OnInit, OnDestroy {
  public readonly defaultMinRows = 5;
  public readonly tableType = ETableType.PARTS_TABLE;
  public readonly DescriptionFormField = DescriptionFormField;

  public fieldProperties = {
    [DescriptionFormField.Title]: {
      name: DescriptionFormField.Title,
      translationKey: 'notifications.form.description.title',
      required: true,
      hasTitle: true,
      hasTooltip: true,
      htmlValidators: { maxLength: 128 },
      control: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.specialCharsValidationService.regExpBlockSpecialChars),
        ]),
      ),
    } as InputTextProperty,

    [DescriptionFormField.Issue]: {
      name: DescriptionFormField.Issue,
      translationKey: 'notifications.form.description.issue',
      required: true,
      hasTitle: true,
      hasTooltip: true,
      htmlValidators: { maxLength: 1024 },
      control: new FormControl('', [Validators.required]),
    } as InputTextProperty,

    [DescriptionFormField.Cause]: {
      name: DescriptionFormField.Cause,
      translationKey: 'notifications.form.description.cause',
      required: true,
      hasTitle: true,
      hasTooltip: true,
      htmlValidators: { maxLength: 1024 },
      control: new FormControl('', [Validators.required]),
    } as InputTextProperty,

    [DescriptionFormField.Effect]: {
      name: DescriptionFormField.Effect,
      translationKey: 'notifications.form.description.effect',
      required: true,
      hasTitle: true,
      hasTooltip: true,
      htmlValidators: { maxLength: 1024 },
      control: new FormControl('', [Validators.required]),
    } as InputTextProperty,
  };

  public readonly tableTranslations: IEditableInputTableTranslationConfig = {
    firstColumnInputDefaultTextTranslation: 'notifications.form.description.affectedParts',
    firstColumnTitle: 'notifications.form.description.affectedParts',
    secondColumnInputDefaultTextTranslation: 'notifications.form.description.partNumber',
    secondColumnTitle: 'notifications.form.description.partNumber',
    titleTranslationKey: 'notifications.form.description.affectedParts.label',
    inputLimit: 128,
  };

  public partsKeys: Array<keyof IPart> = ['id', 'name', 'partNumbers'];

  public registerForm: FormGroup;

  public currentFieldErrors$: Observable<Map<string, boolean>> =
    this.validationService.currentErrorHandlingDescription$;

  public currentNotification: INotification;

  private currentNotification$: Observable<INotification> = this.dataService.currentNotification$;

  private destroyed$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private validationService: ValidationService,
    private specialCharsValidationService: SpecialCharsValidationService,
    private notificationPermissionService: NotificationPermissionService,
    private notificationRouting: NotificationRoutingService,
  ) {}

  public ngOnInit(): void {
    this.createForm();

    this.setupValueChangeHandling();

    this.watchCurrentNotification$();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private createForm(): void {
    this.registerForm = this.formBuilder.group({
      [DescriptionFormField.Title]: this.getFieldControl(DescriptionFormField.Title),
      [DescriptionFormField.PartName]: [''],
      [DescriptionFormField.PartNumber]: [''],
      [DescriptionFormField.Issue]: this.getFieldControl(DescriptionFormField.Issue),
      [DescriptionFormField.Cause]: this.getFieldControl(DescriptionFormField.Cause),
      [DescriptionFormField.Effect]: this.getFieldControl(DescriptionFormField.Effect),
    });
  }

  public onPartsTableDataChange(newValues: IPart[]): void {
    this.currentNotification.parts = newValues;
    this.dataService.updateCurrentNotification(this.currentNotification);
  }

  private getFieldControl(fieldName: string): AbstractControl {
    return this.fieldProperties[fieldName].control;
  }

  private fillFormFields(currentNotification: INotification): void {
    Object.values(DescriptionFormField).forEach((formField: string) => {
      this.registerForm.get(formField).setValue(currentNotification[formField], { emitEvent: false });
    });
  }

  private setupValueChangeHandling(): void {
    Object.values(DescriptionFormField).forEach((formField: string) => {
      this.registerForm
        .get(formField)
        .valueChanges.pipe(takeUntil(this.destroyed$))
        .subscribe(value => {
          this.currentNotification[formField] = value;
          this.updateNotification();
        });
    });
  }

  private updateNotification(): void {
    this.dataService.updateCurrentNotification(this.currentNotification);
  }

  private watchCurrentNotification$(): void {
    const currentNotification$ = defer(() => this.currentNotification$);

    currentNotification$.pipe(takeUntil(this.destroyed$)).subscribe((currentNotification: INotification) => {
      this.currentNotification = { ...currentNotification };
      this.fillFormFields(this.currentNotification);
    });

    currentNotification$
      .pipe(
        first(),
        filter((currentNotification: INotification) => this.isEdit() && !this.isEditable(currentNotification.status)),
      )
      .subscribe(() => {
        this.notificationRouting.navigateToBaseRouteNotification();
      });
  }

  private isEdit(): boolean {
    return !this.notificationRouting.isCreteNotification();
  }

  private isEditable(status: NotificationStatus): boolean {
    return this.notificationPermissionService.canEditNotification(status);
  }
}
