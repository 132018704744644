import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { BrandAPS } from '../../models';

@Component({
  selector: 'recall2-button-brand-manufacturer',
  templateUrl: './recall2-button-brand-manufacturer.component.html',
  styleUrls: ['./recall2-button-brand-manufacturer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Recall2ButtonBrandManufacturerComponent {
  @Input() brand: BrandAPS;
  @Output() downloadEventEmitter = new EventEmitter<BrandAPS>();

  downloadDocument(): void {
    if (!this.brand.enabled) {
      return;
    }

    this.downloadEventEmitter.emit(this.brand);
  }
}
