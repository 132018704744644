import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import type { BrandManufacturer } from '@recall2/ui/brand-manufacturer';
import { SelectOption, SelectProperty } from '@recall2/ui/form/model';
import { ReCall2FormCancelModalComponent } from '@recall2/ui/overlay';
import { ToastService } from '@recall2/ui/toast';
import { Subject } from 'rxjs';
import { first, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { BrandService } from '../../../../rest-api/brand/brand.service';
import { NotificationService } from '../../../../rest-api/notification/notification.service';
import { pathParamNotificationId } from '../../../routing/notification-routes.constants';
import { NotificationRoutingService } from '../../../routing/notification-routing.service';

@Component({
  selector: 'notification-transfer',
  templateUrl: './notification-transfer.component.html',
  styleUrls: ['./notification-transfer.component.scss'],
})
export class NotificationTransferComponent implements OnInit, OnDestroy {
  isFormSubmitted = false;
  manufacturerSelected: BrandManufacturer;
  selectManufacturerProperty: SelectProperty;
  notificationId: number;
  comment: string;

  private brandManufacturers: BrandManufacturer[] = [];
  private destroyed$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private brandService: BrandService,
    private modal: MatDialog,
    private routingService: NotificationRoutingService,
    private notificationService: NotificationService,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(paramMap => {
      this.notificationId = +paramMap.get(pathParamNotificationId);
    });

    this.initForm();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  close(): void {
    this.modal.open(ReCall2FormCancelModalComponent, {
      panelClass: 'recall2-form-cancel-modal',
      backdropClass: 'modal-backdrop',
      data: {
        submitCallback: () => this.routingService.navigateToBaseRouteNotification(this.notificationId, true),
        title: this.translate.instant('notifications.transfer.releaseModal.title'),
        content: this.translate.instant('notifications.transfer.releaseModal.content'),
      },
    });
  }

  selectManufacturer(manufacturerId: string): void {
    this.manufacturerSelected = this.brandManufacturers.find(bm => bm.id.toString() === manufacturerId);
  }

  submit(): void {
    this.isFormSubmitted = true;

    if (!this.manufacturerSelected) {
      return;
    }

    this.notificationService
      .transferNotification(this.notificationId, this.manufacturerSelected.id, this.comment)
      .pipe(
        takeUntil(this.destroyed$),
        switchMap(() => this.routingService.navigateToOverviewAndHighlight(this.notificationId)),
        tap(() => {
          this.toastService.success({
            title: this.translateService.instant('notifications.transfer.toast.title.success'),
            content: this.translateService.instant('notifications.transfer.toast.content.success'),
          });
        }),
        first(),
      )
      .subscribe();
  }

  setComment(comment: string): void {
    this.comment = comment;
  }

  private initForm(): void {
    this.selectManufacturerProperty = new SelectProperty(
      'manufacturerSelect',
      true,
      'notifications.form.select-manufacturer',
      [],
      false,
      new FormControl('', [Validators.required]),
    );
    this.notificationService
      .getNotificationForId(this.notificationId)
      .pipe(
        takeUntil(this.destroyed$),
        switchMap(currentNotification => {
          const assignedManufacturerIds = [];
          assignedManufacturerIds.push(...currentNotification.assignedBrandManufacturers.map(el => el.manufacturer.id));

          return this.brandService
            .getBrandManufacturerForList(currentNotification.createdAt)
            .pipe(
              map(manufacturers => manufacturers.filter(el => !assignedManufacturerIds.includes(el.manufacturer.id))),
            );
        }),
      )
      .subscribe(response => {
        this.brandManufacturers = response;
        const optionsList = this.brandManufacturers.map(bm => new SelectOption(bm.manufacturer.name, `${bm.id}`));
        this.selectManufacturerProperty = { ...this.selectManufacturerProperty, optionsList };
      });
  }
}
