import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ReCall2CoreModule } from '@recall2/ui/core.module';
import { Recall2IconLogoRecallComponent } from '@recall2/ui/icons';

import { UIModule } from '../ui/ui.module';
import { CreateNotificationButtonComponent } from './components/create-notification-button/create-notification-button.component';
import { HomePageComponentsModule } from './components/home-page-components.module';
import { MyServePageComponent } from './my-serve-page/my-serve-page.component';
import { WelcomePageModule } from './welcome-page/welcome-page.module';

@NgModule({
  declarations: [MyServePageComponent],
  exports: [CreateNotificationButtonComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ReCall2CoreModule,
    RouterModule,
    HomePageComponentsModule,
    WelcomePageModule,
    UIModule,
    Recall2IconLogoRecallComponent,
  ],
})
export class HomePageModule {}
