import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { TabTitleService } from '@recall2/ui/tab-title';

@Component({
  selector: 'welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss'],
})
export class WelcomePageComponent implements OnInit {
  constructor(private tabTitleService: TabTitleService) {}

  ngOnInit(): void {
    this.tabTitleService.setTitleByKey('preliminary.welcome.tab-title');
  }
}
