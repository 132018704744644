import type { OnDestroy, OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import type { IAutoSuggestConfiguration } from '@recall2/ui/auto-suggest';
import { EAutoSuggestObjectType, Recall2AutoSuggestComponent } from '@recall2/ui/auto-suggest';
import { CommentsAndHistoryRefreshable } from '@recall2/ui/comments-history';
import { EObjectType } from '@recall2/ui/dynamic-content';
import { InputTextProperty } from '@recall2/ui/form/model';
import type { IUser } from '@recall2/user';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { AutoSuggestService } from '@/rest-api/auto-suggest/auto-suggest.service';
import type { UserData } from '@/user/store/user.state';

import type { IAppState } from '../../../../store/app/app.state';
import { UserSelectors } from '../../../../user/store/user.selectors';

@Component({
  selector: 'recall2-notification-confirm',
  templateUrl: './notification-confirm.component.html',
})
export class NotificationConfirmComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() message: string;

  @Input() showSelectUser: boolean;

  @Input() commentRequired: boolean;
  @Input() currentObjectId: number;
  @Input() isFormSubmitted: boolean;
  @ViewChild('autoSuggest') autoSuggest: Recall2AutoSuggestComponent;
  @ViewChild('commmentHistory') commmentHistory: CommentsAndHistoryRefreshable;

  @Output() onSelectedUser = new EventEmitter<IUser>();
  @Output() onCommentAdded = new EventEmitter<string>();

  // user autoSuggest
  readonly autoSuggestType = EAutoSuggestObjectType;
  readonly objectType = EObjectType.NOTIFICATION;
  autoSuggestConfiguration: IAutoSuggestConfiguration;
  users: UserData[];
  isSpinnerShowing: boolean;

  // current user
  private destroyed$ = new Subject<void>();
  currrentUserId: string;

  property = new InputTextProperty(
    'autoSuggest.user',
    true,
    'autoSuggest.user',
    {},
    false,
    new FormControl('', [Validators.required]),
    true,
  );

  constructor(
    protected autosuggestService: AutoSuggestService,
    private store: Store<IAppState>,
  ) {}

  ngOnInit(): void {
    this.store
      .select(UserSelectors.getUser)
      .pipe(
        takeUntil(this.destroyed$),
        filter(u => !!u),
        map(res => res.vwUserId),
      )
      .subscribe(vwUserId => {
        this.currrentUserId = vwUserId;
      });
    this.setupAutoSuggestConfiguration();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onSearchTermChanged(searchTerm: string): void {
    this.isSpinnerShowing = true;
    this.autosuggestService
      .searchUsersByString(searchTerm)
      .pipe(filter(entriesList => !!entriesList))
      .subscribe({
        next: res => {
          this.users = res.content.filter(res => res.vwUserId !== this.currrentUserId);
          this.isSpinnerShowing = false;
        },
      });
  }

  selectedUser(user: IUser): void {
    this.onSelectedUser.emit(this.isUserValid() ? user : undefined);
  }

  commentChanged(value: string): void {
    this.onCommentAdded.emit(value);
  }

  private setupAutoSuggestConfiguration(): void {
    this.autoSuggestConfiguration = {
      property: this.property,
      searchDirectoryEnabled: true,
    };
  }

  private isUserValid(): boolean {
    return !this.autoSuggestConfiguration.property.control.errors;
  }
}
