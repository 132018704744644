<div class="rc2-overview-wrapper pad-bottom-erase">
  <div class="rc2-mar--bottom--4xl">
    <h2
      data-testid="review-header"
      class="rc2-mar--bottom--3xl"
    >
      {{ 'notifications.form.forwardBrandModal.title' | translate }}
    </h2>
    <div class="rc2-display--flex rc2-mar--bottom--m">
      <div class="col-md-7 rc2-pad--no">
        <div
          class="rc2-mar--bottom--m rc2-font--body--l rc2-pad--right--m"
          data-testid="forward-subheader"
        >
          {{ 'notifications.form.forwardBrandModal.subtitle' | translate }}
        </div>
      </div>
      <div class="col-md-5 rc2-pad--no">
        <recall2-notification-warning></recall2-notification-warning>
      </div>
    </div>
    <div class="rc2-border--bottom--grey--200-tertiary rc2-pad--bottom--s rc2-mar--bottom--xl">
      <h4>{{ 'notifications.manufacturer' | translate }} *</h4>
    </div>
    <recall2-brand-manufacturer
      class="rc2-mar--bottom--m"
      *ngIf="brandManufacturerConfiguration"
      [brandManufacturerSelectorConfiguration]="brandManufacturerConfiguration"
      (updateVisibleManufacturer)="updateManufacturerSelection($event)"
      [isSubmitted]="isFormSubmitted"
    >
    </recall2-brand-manufacturer>
    <div class="rc2-border--bottom--grey--200-tertiary rc2-pad--bottom--s">
      <h4>{{ 'notifications.form.category.comments' | translate }}</h4>
    </div>
    <div
      class="rc2-border--bottom--grey--200-tertiary rc2-pad--bottom--s rc2-mar--bottom--m comments-history-container">
      <recall2-notification-comment
        [isRequired]="false"
        [isFormSubmitted]="false"
        (onValueChanged)="setComment($event)"
      ></recall2-notification-comment>
      <recall2-comments-and-history
        #commmentHistory
        [objectType]="objectType"
        [objectId]="notificationId"
        [commentRequired]="false"
        [isFormSubmitted]="false"
        [commentsDisabled]="true"
      ></recall2-comments-and-history>
    </div>
    <div class="rc2-font--body--s rc2-color--grey-900">
      *) {{ 'notifications.form.requiredField.hint' | translate }}
    </div>
  </div>

  <recall2-floating-bar>
    <div class="action-bar">
      <recall2-button-tertiary
        class="action-bar__button"
        [iconClass]="'icon-i32-remove-item'"
        (click)="cancelSend()"
      >
        {{ 'notifications.send.button.cancel' | translate }}
      </recall2-button-tertiary>
      <recall2-button-primary
        class="action-bar__button"
        [iconClass]="'icon-i30-forward'"
        [forceAnimation]="isForwarding"
        [isDisabled]="isEveryBrandManufacturerForwarded"
        [matTooltip]="isEveryBrandManufacturerForwarded ? ('notifications.form.all-manufacturers-forwarded' | translate) : ''"
        matTooltipClass="rc2-tooltip"
        matTooltipPosition="above"
        (click)="forwardNotificationToManufacturer()"
      >
        {{ 'notifications.form.forwardModal.sendButton' | translate }}
      </recall2-button-primary>
    </div>
  </recall2-floating-bar>
</div>
