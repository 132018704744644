import { NgClass, NgIf } from '@angular/common';
import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { Recall2ButtonOutlineAddComponent } from '@recall2/ui/buttons';
import type { IPart, IPartNumber } from '@recall2/ui/core/models';
import { Recall2InputTextComponent } from '@recall2/ui/form/components/recall2-input-text';
import { Recall2TagsComponent } from '@recall2/ui/form/components/recall2-tags';
import { InputTextProperty } from '@recall2/ui/form/model';
import { Recall2IconDeleteComponent } from '@recall2/ui/icons';
import { compareObjects } from '@recall2/ui/utils';

import { IEditableInputTableTranslationConfig } from './i-editable-input-table-translation-config';
import { ETableType } from './table-type';

export interface FormTuple<T> {
  formGroup: FormGroup;
  firstColumnInputProp: InputTextProperty;
  secondColumnInputProp: InputTextProperty;
  tuple: T;
}

@Component({
  selector: 'editable-input-table',
  templateUrl: './editable-input-table.component.html',
  styleUrls: ['./editable-input-table.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    Recall2InputTextComponent,
    NgIf,
    NgClass,
    Recall2TagsComponent,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    Recall2ButtonOutlineAddComponent,
    Recall2IconDeleteComponent,
  ],
})
export class EditableInputTableComponent<T extends Pick<T, keyof T>> implements OnInit, OnChanges {
  @Input()
  translations: IEditableInputTableTranslationConfig;

  @Input()
  initialData: T[] = [];

  @Input()
  keys: Array<keyof T>;

  @Input()
  tableType = ETableType.STRING_TABLE;

  @Input()
  disabled = true;

  @Input()
  isFormSubmitted = false;

  @Output()
  tableDataChange = new EventEmitter<T[]>();

  tableData: FormTuple<T>[] = [];

  readonly displayedColumns = ['firstColumn', 'secondColumn', 'deleteIcon'];

  isPartsTable = false;

  ngOnInit(): void {
    this.isPartsTable = this.tableType === ETableType.PARTS_TABLE;
    this.applyInitData();
    if (
      this.tableData[0] === undefined ||
      (this.tableData[0] && this.tableData[0].firstColumnInputProp.control.value !== '')
    ) {
      this.disabled = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasRelevantChanges(changes)) {
      this.applyInitData();
    }
  }

  showDeleteIcon(): boolean {
    return this.tableData.length > 1;
  }
  addRow(tuple: T): void {
    // add new empty row at table top
    const firstProp = this.createInputTextProperty(
      'firstColumnInput',
      tuple[this.keys[1]],
      this.translations.firstColumnInputDefaultTextTranslation,
      this.translations.inputLimit ? this.translations.inputLimit : 0,
    );

    const secondProp = this.createInputTextProperty(
      'secondColumnInput',
      tuple[this.keys[2]],
      this.translations.secondColumnInputDefaultTextTranslation,
      this.translations.inputLimit ? this.translations.inputLimit : 0,
    );

    this.tableData = [
      {
        formGroup: this.createFormGroupForRow(firstProp.control, secondProp.control),
        firstColumnInputProp: firstProp,
        secondColumnInputProp: secondProp,
        tuple,
      },
      ...this.tableData,
    ];
  }

  addNewRow(): void {
    if (!this.disabled) {
      const newRow = {} as T;
      newRow[this.keys[0]] = null;
      newRow[this.keys[1]] = '' as unknown as T[keyof T];
      newRow[this.keys[2]] = '' as unknown as T[keyof T];
      if (this.tableType === ETableType.PARTS_TABLE) {
        newRow[this.keys[2]] = [] as unknown as T[keyof T];
      }
      this.addRow(newRow);
    }
    this.disabled = true;
  }

  removeTableRow(index: number): void {
    this.tableData.splice(index, 1);
    this.tableData = [...this.tableData];
    this.emitTableData();
    if (this.tableData.length === 1 || index === 0) {
      this.disabled = false;
    }
  }

  updateFirstColumn(): void {
    this.disabled = false;
    for (const index in this.tableData) {
      this.tableData[index].tuple[this.keys[1]] = this.tableData[index].firstColumnInputProp.control.value;
    }
    this.emitTableData();
    if (this.tableData[0].firstColumnInputProp.control.value === '') {
      this.disabled = true;
    }
  }

  updateSecondColumn(): void {
    for (const index in this.tableData) {
      this.tableData[index].tuple[this.keys[2]] = this.tableData[index].secondColumnInputProp.control.value;
    }
    this.emitTableData();
  }

  updateTags(index: number, tags: string[]): void {
    const tuple: IPart = this.tableData[index].tuple;

    if (tags.length > tuple.partNumbers.length) {
      // add the new tag to the parts array
      // We currently slice it to 64 chars to keep the component savable, the component should enable a native length check shortly
      // the story to check should be linked in RECALLTWO-7152
      const tagToAdd = tags[tags.length - 1].slice(0, 64);
      if (!tuple.partNumbers.some(partNumber => partNumber.number === tagToAdd)) {
        tuple.partNumbers.push(<IPartNumber>{ id: null, number: tagToAdd });
      }
      this.emitTableData();
    }
  }

  onRemoveTag(index: number, removedTagIndex: number): void {
    const tuple: IPart = this.tableData[index].tuple;
    tuple.partNumbers.splice(removedTagIndex, 1);
    this.emitTableData();
  }

  getPartsStringList(parts: IPartNumber[]): string[] {
    const partsList = [];
    for (const part of parts) {
      partsList.push(part.number);
    }
    return partsList;
  }

  showTableError(): boolean {
    const isTableEmpty = !this.tableData[0];
    return this.isFormSubmitted && isTableEmpty;
  }

  showFirstColumnError(index: number): boolean {
    const isFirstColumnError = this.tableData[index].tuple[this.keys[1]] === '';
    return this.isFormSubmitted && isFirstColumnError;
  }

  showSecondColumnError(): boolean {
    const isSecondColumnError = this.tableData.some(row => {
      const secondColumnData = row.tuple[this.keys[2]];
      return this.isPartsTable ? (secondColumnData as IPart[]).length === 0 : secondColumnData === '';
    });
    return this.isFormSubmitted && isSecondColumnError;
  }

  private emitTableData(): void {
    const tableData = this.tableData.map(data => {
      return data.tuple;
    });
    const reverseTestData = [...tableData].reverse();
    this.tableDataChange.emit(reverseTestData);
  }

  private createInputTextProperty(
    name: string,
    value: T[keyof T],
    translation: string,
    inputLimit: number,
  ): InputTextProperty {
    return new InputTextProperty(
      name,
      true,
      translation,
      inputLimit < 1 ? {} : { maxLength: inputLimit },
      false,
      new FormControl(value, [Validators.required]),
      false,
    );
  }

  private createFormGroupForRow(firstControl: FormControl, secondControl: FormControl): FormGroup {
    return new FormGroup({
      firstColumnForm: firstControl,
      secondColumnForm: secondControl,
    });
  }

  private applyInitData(): void {
    this.tableData = [];
    if (this.initialData) {
      for (const data of this.initialData) {
        this.addRow(data);
      }
    }
  }

  private hasRelevantChanges(changes: SimpleChanges): boolean {
    const currentTuples = this.tableData.map(data => data.tuple).reverse();
    if (changes.initialData && changes.initialData.currentValue) {
      return !compareObjects(currentTuples, changes.initialData.currentValue);
    }
    return false;
  }
}
