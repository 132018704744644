import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Recall2AutoSuggestComponent, Recall2DirectorySearchComponent } from '@recall2/ui/auto-suggest';
import { Recall2ButtonOutlineAddComponent } from '@recall2/ui/buttons';
import { Recall2DynamicContentComponent } from '@recall2/ui/dynamic-content';
import { Recall2CheckboxComponent } from '@recall2/ui/form/components/recall2-checkbox';
import { Recall2InputTextComponent } from '@recall2/ui/form/components/recall2-input-text';
import { Recall2ThreeStepCheckboxComponent } from '@recall2/ui/form/components/recall2-three-step-checkbox';
import { Recall2IconDeleteComponent, Recall2IconObjektBenutzerComponent } from '@recall2/ui/icons';
import { ReCall2LinkComponent } from '@recall2/ui/link';
import { ThreeStepCellComponent } from '@recall2/ui/manufacturer-table';
import { Recall2BasicTableComponent } from '@recall2/ui/tables';

import { AutoSuggestCell } from './cells/involved-user-table/auto-suggest-cell/auto-suggest-cell.component';
import { CommitteeTranscriberCell } from './cells/involved-user-table/committee-transcriber-cell/committee-transcriber.cell';
import { DeleteIconCell } from './cells/involved-user-table/delete-icon-cell/delete-icon.cell';
import { EntitledToVoteCell } from './cells/involved-user-table/entitled-to-vote-cell/entitled-to-vote.cell';
import { InheritToAgendaItemCell } from './cells/involved-user-table/inherit-to-agenda-item-cell/inherit-to-agenda-item.cell';
import { SubjectAreaEditCell } from './cells/involved-user-table/subject-area-edit-cell/subject-area-edit-cell.component';
import { DepartmentCell } from './cells/involved-users-summary-table/department-cell/department-cell.component';
import { EmailCell } from './cells/involved-users-summary-table/email-cell/email-cell.component';
import { IdNameCell } from './cells/involved-users-summary-table/id-name-cell/id-name-cell.component';
import { PhoneCell } from './cells/involved-users-summary-table/phone-cell/phone-cell.component';
import { SubjectAreaCell } from './cells/involved-users-summary-table/subject-area-cell/subject-area-cell.component';
import { Recall2InvolvedUsersTableComponent } from './components/recall2-involved-users-table/recall2-involved-users-table.component';
import { UserNamePipe } from './pipes/user-name.pipe';
import { UserService } from './user.service';

const CELLS = [
  DepartmentCell,
  EmailCell,
  IdNameCell,
  PhoneCell,
  SubjectAreaCell,
  AutoSuggestCell,
  SubjectAreaEditCell,
  EntitledToVoteCell,
  InheritToAgendaItemCell,
  CommitteeTranscriberCell,
  DeleteIconCell,
];

@NgModule({
  declarations: [Recall2InvolvedUsersTableComponent, UserNamePipe, CELLS],
  imports: [
    CommonModule,
    TranslateModule,
    Recall2DynamicContentComponent,
    RouterModule,
    Recall2DirectorySearchComponent,
    MatDialogModule,
    Recall2AutoSuggestComponent,
    MatTooltipModule,
    ReCall2LinkComponent,
    Recall2ButtonOutlineAddComponent,
    ThreeStepCellComponent,
    Recall2InputTextComponent,
    Recall2IconObjektBenutzerComponent,
    Recall2IconDeleteComponent,
    Recall2CheckboxComponent,
    Recall2ThreeStepCheckboxComponent,
    Recall2BasicTableComponent,
  ],
  exports: [Recall2InvolvedUsersTableComponent, CELLS, UserNamePipe],
})
export class Recall2UserLibraryModule {
  static forFeature(UserServiceImpl): ModuleWithProviders<Recall2UserLibraryModule> {
    return {
      ngModule: Recall2UserLibraryModule,
      providers: [
        {
          provide: UserService,
          useExisting: UserServiceImpl,
          deps: [HttpClient],
        },
      ],
    };
  }
}
