import { Component, Input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EThreeStepCheckboxStates } from '@recall2/ui/form/model';
import { CustomDatePipe } from '@recall2/ui/pipes';

import { ThreeStateTranslatePipe } from '@/ui/pipes/three-state-to-translation/three-state-to-translation.pipe';

import { INotification } from '../../../../models/notification/notification';
import { SummaryTextComponent } from '../summary-text/summary-text.component';

@Component({
  selector: 'notification-summary-measurements',
  templateUrl: './notification-summary-measurements.component.html',
  styleUrls: ['./notification-summary-measurements.component.scss'],
  providers: [CustomDatePipe],
  standalone: true,
  imports: [SummaryTextComponent, TranslateModule, ThreeStateTranslatePipe],
})
export class NotificationSummaryMeasurementsComponent {
  @Input() public notification: INotification;

  constructor(
    private translateService: TranslateService,
    private datePipe: CustomDatePipe,
  ) {}

  public getMeasureImplementedAsString(): string {
    if (this.notification.measureImplemented === EThreeStepCheckboxStates.SELECTED) {
      const translated = this.translateService.instant('general.true');
      const date = this.datePipe.transform(this.notification.implementationDate);

      return `${translated} / ${date}`;
    }

    return this.translateService.instant('general.false');
  }

  public getWarehouseNumberAsString(): string {
    if (this.notification.warehouseUpdated === EThreeStepCheckboxStates.SELECTED) {
      const translated = this.translateService.instant('general.true');
      return `${translated} / ${this.notification.warehouseUpdateNumber}`;
    }

    return this.translateService.instant('general.false');
  }

  public getServiceAvailableAsString(): string {
    if (this.notification.measureAvailable === EThreeStepCheckboxStates.SELECTED) {
      const translated = this.translateService.instant('general.true');
      const date = this.datePipe.transform(this.notification.availabilityDate);

      return `${translated} / ${date}`;
    }

    return this.translateService.instant('general.false');
  }

  public getAffectedManufacturersAsString(): string {
    const names = this.notification.brandManufacturers.map(brandManufacturer => brandManufacturer.manufacturer.name);

    return names.join(', ');
  }
}
