import { Component, Input } from '@angular/core';

export enum DisplayedComponent {
  History = 'HISTORY',
  Attachment = 'ATTACHMENT',
}
export type ObjectType = 'NOTIFICATION';

@Component({
  selector: 'slider-content',
  templateUrl: './slider-content.component.html',
  styleUrls: ['./slider-content.component.scss'],
})
export class SliderContentComponent {
  @Input() objectId: number;

  @Input() display: DisplayedComponent;

  readonly displayedComponent = DisplayedComponent;
  readonly objectType: ObjectType = 'NOTIFICATION';
}
