import { NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import type { IPart, IPartNumber } from '@recall2/ui/core/models';

import { INotification } from '../../../../models/notification/notification';
import { SummaryTextComponent } from '../summary-text/summary-text.component';

@Component({
  selector: 'notification-summary-header',
  templateUrl: './notification-summary-header.component.html',
  standalone: true,
  imports: [SummaryTextComponent, NgFor, TranslateModule],
})
export class NotificationSummaryHeaderComponent {
  @Input() public notification: INotification;

  public getPartEntryAsString(part: IPart): string {
    const numbers = part.partNumbers.map((partNumber: IPartNumber) => partNumber.number);
    const numbersAsString = numbers.join(', ');

    return `${part.name} / ${numbersAsString}`;
  }
}
