import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { NotificationsService } from '@recall2/notifications';
import { ERole } from '@recall2/ui/navbar';
import { SafeNavigationService } from '@recall2/ui/safe-navigation';
import { ToastNotificationsRendererService } from '@recall2/ui/toast';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import type { IAppState } from './store/app/app.state';
import { UserSelectors } from './user/store/user.selectors';
import type { UserData } from './user/store/user.state';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  roles: ERole[] = [];
  private destroyed$ = new Subject<void>();

  constructor(
    private store: Store<IAppState>,
    private toastNotificationsRendererService: ToastNotificationsRendererService,
    private notificationsService: NotificationsService,
    private safeNavigationService: SafeNavigationService,
  ) {
    this.safeNavigationService.handleNavigationErrors();
  }

  ngOnInit(): void {
    this.initUserData();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private initUserData(): void {
    this.store
      .select(UserSelectors.getUser)
      .pipe(
        takeUntil(this.destroyed$),
        filter(user => !!user),
      )
      .subscribe((user: UserData) => {
        if (!user.unknownUser) {
          this.setupNotificationsChannel(user);
        }

        this.roles = this.mapUserRoleToRole(user.roles);
      });
  }

  private async setupNotificationsChannel(user: UserData): Promise<void> {
    this.toastNotificationsRendererService.subscribeToNotifications();

    await this.notificationsService.connect(user.uuid);
  }

  private mapUserRoleToRole(roles: ERole[]): ERole[] {
    const result = [];
    if (roles) {
      for (const role of roles) {
        result.push(ERole[role]);
      }
    }
    return result;
  }
}
