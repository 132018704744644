import { Component } from '@angular/core';
import { AbstractGenericCellViewWithData } from '@recall2/ui/dynamic-content';
import { ERole } from '@recall2/ui/navbar';
import type { Recall2TableService, WithTableService } from '@recall2/ui/tables';

import type { InvolvedUser } from '../../../model/involved-user.model';

@Component({
  selector: 'delete-icon-cell',
  templateUrl: './delete-icon.cell.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DeleteIconCell extends AbstractGenericCellViewWithData<InvolvedUser> implements WithTableService {
  public static readonly selector = 'recall2-involved-user-delete-icon-cell';
  public static readonly removeEvent = 'onRemove';

  public readonly EXPERT: string = ERole[ERole.EXPERT];

  private tableService: Recall2TableService;

  public propertyName: string;
  public additionalCssClasses: string[];

  public onRemove(): void {
    this.tableService.triggerCellEvent<InvolvedUser>(DeleteIconCell.selector, DeleteIconCell.removeEvent, this.data);
  }

  public setTableService(tableService: Recall2TableService): void {
    this.tableService = tableService;
    this.tableService.registerCellEvent<InvolvedUser>(DeleteIconCell.selector, DeleteIconCell.removeEvent);
  }
}

export class DeleteIconCellDataBinding implements IDeleteIconBinding {
  constructor(
    public propertyName?: string,
    public additionalCssClasses?: string[],
  ) {}
}

interface IDeleteIconBinding {
  propertyName?: string;
  additionalCssClasses?: string[];
}
