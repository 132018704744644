import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MarkEmptyPipe } from '@recall2/ui/pipes';

@Component({
  selector: 'recall2-summary-text',
  templateUrl: './summary-text.component.html',
  standalone: true,
  imports: [NgIf, MarkEmptyPipe],
})
export class SummaryTextComponent {
  @Input() public label: string;
  @Input() public content: string;
}
